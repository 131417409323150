// Spacers
//
// Spacer types with chainable modifiers.
//
// Markup:
// <div class='spacer--{{modifier_class}}'></div>
//
// --1 - 1 rem padding.
// --2 - 2 rem padding.
// --3 - 3 rem padding.
// --4 - 4 rem padding.
// --5 - 5 rem padding.
// --6 - 6 rem padding.
// --top - Top padding.
// --bottom - Bottom padding.
// --left - Bottom padding.
// --right - Bottom padding.
// --horizontal - Left and right padding.
// --vertical - Top and bottom padding.
// --md - Medium padding.
// --sm - Small padding.
// --xs - Extra small padding.
//
// Styleguide Base.helpers
.spacer {
  @for $i from 0 through 6 {
    &--#{$i} {
      padding: $i * 1rem;

      &--top {
        padding-top: $i * 1rem;
      }

      &--right {
        padding-right: $i * 1rem;
      }

      &--bottom {
        padding-bottom: $i * 1rem;
      }

      &--left {
        padding-left: $i * 1rem;
      }

      &--horizontal {
        padding-left: $i * 1rem;
        padding-right: $i * 1rem;
      }

      &--vertical {
        padding-bottom: $i * 1rem;
        padding-top: $i * 1rem;
      }

      &--left-bottom {
        padding-left: $i * 1rem;
        padding-bottom: $i * 1rem;
      }

      &--left-top {
        padding-left: $i * 1rem;
        padding-top: $i * 1rem;
      }

      &--right-bottom {
        padding-right: $i * 1rem;
        padding-bottom: $i * 1rem;
      }

      &--right-top {
        padding-right: $i * 1rem;
        padding-top: $i * 1rem;
      }
    }
  }

  &--lg {
    padding: $large-spacing;

    &--top {
      padding-top: $large-spacing;
    }

    &--bottom {
      padding-bottom: $large-spacing;
    }

    &--left {
      padding-left: $large-spacing;
    }

    &--right {
      padding-right: $large-spacing;
    }

    &--horizontal {
      padding-left: $large-spacing;
      padding-right: $large-spacing;
    }

    &--vertical {
      padding-bottom: $large-spacing;
      padding-top: $large-spacing;
    }
  }


  &--md {
    padding: $base-spacing;

    &--top {
      padding-top: $base-spacing;
    }

    &--bottom {
      padding-bottom: $base-spacing;
    }

    &--left {
      padding-left: $base-spacing;
    }

    &--right {
      padding-right: $base-spacing;
    }

    &--horizontal {
      padding-left: $base-spacing;
      padding-right: $base-spacing;
    }

    &--vertical {
      padding-bottom: $base-spacing;
      padding-top: $base-spacing;
    }
  }

  &--sm {
    padding: $small-spacing;

    &--top {
      padding-top: $small-spacing;
    }

    &--bottom {
      padding-bottom: $small-spacing;
    }

    &--left {
      padding-left: $small-spacing;
    }

    &--right {
      padding-right: $small-spacing;
    }

    &--horizontal {
      padding-left: $small-spacing;
      padding-right: $small-spacing;
    }

    &--vertical {
      padding-bottom: $small-spacing;
      padding-top: $small-spacing;
    }
  }

  &--xs {
    padding: ($small-spacing / 2);

    &--top {
      padding-top: ($small-spacing / 2);
    }

    &--bottom {
      padding-bottom: ($small-spacing / 2);
    }

    &--left {
      padding-left: ($small-spacing / 2);
    }

    &--right {
      padding-right: ($small-spacing / 2);
    }

    &--horizontal {
      padding-left: ($small-spacing / 2);
      padding-right: ($small-spacing / 2);
    }

    &--vertical {
      padding-bottom: ($small-spacing / 2);
      padding-top: ($small-spacing / 2);
    }
  }
}

