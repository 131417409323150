// Text helper
//
// Center text.
//
// Markup:
// <div class='bg{{modifier_class}}'>Content</div>
//
// --primary - Primary text color.
// --secondary - Secondary text color.
// --info - Info text color.
// --success - Success text color.
// --warning - Warning text color.
// --danger - Danger text color.
// --light - Light text color.
// --dark - Dark text color.
//
// Styleguide Base.helpers
.bg {
  @each $name, $value in $action-colors {
    &--#{$name} {
      background-color: $value !important;
    }
  }

  @each $name, $value in $pastel-colors {
    &--#{$name}-pastel {
      background-color: $value !important;
    }
  }

  @each $name, $value in $greys {
    &--#{$name} {
      background-color: $value !important;
    }
  }

  @each $name, $value in $beiges {
    &--#{$name} {
      background-color: $value !important;
    }
  }

  @each $name, $value in $blues {
    &--#{$name} {
      background-color: $value !important;
    }
  }

  @each $name, $value in $greens {
    &--#{$name} {
      background-color: $value !important;
    }
  }

  @each $name, $value in $oranges {
    &--#{$name} {
      background-color: $value !important;
    }
  }

  @each $name, $value in $reds {
    &--#{$name} {
      background-color: $value !important;
    }
  }

  @each $name, $value in $indigos {
    &--#{$name} {
      background-color: $value !important;
    }
  }

  @each $name, $value in $purples {
    &--#{$name} {
      background-color: $value !important;
    }
  }

  @each $name, $value in $nav-colors {
    &--#{$name} {
      background-color: $value !important;
    }
  }
}
