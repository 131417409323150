// Headings
//
// Styles for headings.
//
// Markup:
// <h1 class='heading {{modifier_class}}'>Heading</h1>
//
// --h1 - Heading 1.
// --h2 - Heading 2.
// --h3 - Heading 3.
// --h4 - Heading 4.
// --h5 - Heading 5.
// --h6 - Heading 6.
// --group - Heading group.
// --inline - Inline heading.
// --inline-block - Inline block heading.
//
// Styleguide Styleguide.typography
.heading {
  @for $i from 1 through 6 {
    &--#{$i} {
      font-size: modular-scale(6 - $i, $base-font-size, $minor-third);

      > .icon {
        margin-right: ($small-spacing / 2);
      }

      &--group {
        display: flex;
        font-size: modular-scale(6 - $i, $base-font-size, $minor-third);
        margin-bottom: 0;

        span {
          $height: modular-scale(6 - $i, $base-font-size, $minor-third) / 10;

          color: map-get($text-colors, 'grey');
          flex: 1;
          font-size: $base-font-size;
          line-height: strip-unit($height);
          padding-right: $base-spacing;
          text-align: right;
          vertical-align: baseline;
        }
      }
    }
  }

  &--inline-block {
    display: inline-block;
  }

  &--inline {
    display: inline;
  }

  &--group {
    align-items: flex-end;
    display: flex;
    line-height: 2.4;
    margin-bottom: 0;

    > span {
      flex: 1;
    }

    .btn {
      flex: 0 0 auto;
      font-family: $base-font-family;
      line-height: 2;
      padding: $small-spacing 1.5rem $small-spacing 0;
    }
  }
}
