// Base shadow selector
//
// Add shadow and border effects to elements.
//
// Styleguide Base.selectors
%base-shadow {
  border: 1px solid $grey-300;
  border-radius: $base-border-radius;
  box-shadow: (
    inset 0 3px 0 0 $grey-150,
    0 1px 2px 0 rgba($grey-900, .1)
  );
}

// Primary shadow selector
//
// Add shadow and border effects to primary buttons.
//
// Styleguide Base.selectors
%primary-shadow {
  box-shadow: (
    inset 0 -3px 0 0 rgba($base-shadow-color, .25),
    0 1px 2px 0 rgba($grey-900, .1)
  );
}

// Primary shadow selector for hover state
//
// Add shadow and border effects to hovered primary buttons.
//
// Styleguide Base.selectors
%primary-hover-shadow {
  box-shadow: (
    inset 0 -3px 0 0 rgba($base-shadow-color, .25),
    0 2px 6px 0 rgba($grey-900, .15),
    0 1px 2px 0 rgba($grey-900, .15)
  );
}

// Primary shadow selector for focus state
//
// Add shadow and border effects to focused primary buttons.
//
// Styleguide Base.selectors
%primary-focus-shadow {
  box-shadow: (
    $focus-box-shadow,
    inset 0 0 0 2px $white,
    inset 0 -3px 0 0 rgba($base-shadow-color, .15),
    0 1px 2px 0 rgba($base-shadow-color, .1)
  );
}

// Primary shadow selector for active state
//
// Add shadow and border effects to active primary buttons.
//
// Styleguide Base.selectors
%primary-active-shadow {
  box-shadow: (
    inset 0 2px 0 0 rgba($base-shadow-color, .25),
    0 1px 2px 0 rgba($base-shadow-color, .1)
  );
}

// Secondary shadow selector
//
// Add shadow and border effects to secondary buttons.
//
// Styleguide Base.selectors
%secondary-shadow {
  box-shadow: (
    inset 0 -3px 0 0 rgba($grey-150, 0),
    inset 0 1px 2px 0 rgba($base-shadow-color, .1),
    0 0 0 1px $grey-350
  );
}

// Secondary shadow selector for hover state
//
// Add shadow and border effects to hovered secondary buttons.
//
// Styleguide Base.selectors
%secondary-hover-shadow {
  box-shadow: (
    inset 0 -3px 0 0 rgba($grey-150, 0),
    0 2px 6px 0 rgba($grey-900, .15),
    0 1px 2px 0 rgba($grey-900, .15),
    0 0 0 1px $grey-350
  );
}

// Secondary shadow selector for focus state
//
// Add shadow and border effects to focused secondary buttons.
//
// Styleguide Base.selectors
%secondary-focus-shadow {
  box-shadow: (
    $focus-box-shadow,
    inset 0 -3px 0 0 rgba($grey-150, 0),
    0 1px 2px 0 rgba($base-shadow-color, .9)
  );
}

// Secondary shadow selector for active state
//
// Add shadow and border effects to active secondary buttons.
//
// Styleguide Base.selectors
%secondary-active-shadow {
  box-shadow: (
    inset 0 3px 0 0 rgba($grey-150, 0),
    0 1px 2px 0 rgba($base-shadow-color, .1),
    0 0 0 1px $grey-350
  );
}

// Secondary shadow selector for disabled state
//
// Add shadow and border effects to disabled secondary buttons.
//
// Styleguide Base.selectors
%secondary-disabled-shadow {
  box-shadow: (
    inset 0 -1px 2px 0 rgba($base-shadow-color, .15),
    0 1px 2px 0 rgba($base-shadow-color, .1)
  );
}
