// Pull
//
// Flex box pull types with chainable modifier
//
// Markup:
// <div class='pull--{{modifier_class}}'></div>
//
// --left - Pull element to left.
// --right - Pull element to right.
//
// Styleguide Base.helpers
.pull {
  &--right {
    margin-left: auto;
  }

  &--left {
    margin-right: auto;
  }
}
