// Sizes
//
// Min width helpers
//
// Markup:
// <div class='min-width--{{modifier_class}}'></div>
//
// --50 - 50 px.
// --100 - 100 px.
// --150 - 150 px.
// --200 - 200 px.
// --250 - 250 px.
// --300 - 300 px.
// --350 - 350 px.
//
// Styleguide Base.helpers
.min-width {
  @for $i from 1 through 7 {
    &--#{$i * 50} {
      min-width: $i * 50px;
    }
  }
}


// Height
.height {
  @for $i from 1 through 10 {
    &--#{$i * 10} {
      height: $i * 10%;
    }
  }
}
