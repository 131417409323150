// Typography defaults
//
// Set default typography.
//
// Styleguide Base.typography
body,
html {
  color: $base-text-color;
  font-family: $base-font-family;
  font-size: 100%;
  line-height: $base-line-height;
}

// Headings
//
// Styles for headings.
//
// Markup:
// <h1>Heading</h1>
//
// Styleguide Base.typography
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $base-text-color;
  font-family: $heading-font-family;
  font-weight: 400;
  margin: 0 0 $small-spacing;
}

@for $i from 1 through 6 {
  h#{$i} {
    font-size: modular-scale(6 - $i, $base-font-size, $minor-third);
  }
}

// Paragraphs
//
// Styles for paragraphs.
//
// Markup:
// <p>Lorem ipsum ...</p>
//
// Styleguide Base.typography
p {
  margin: 0 0 $small-spacing;

  a {
    color: $base-link-color;
  }
}

// Links
//
// Styles for links.
//
// Markup:
// <a href='#'>Link</a>
//
// :hover - Input hover.
// :focus - Input focus.
//
// Styleguide Base.typography
a {
  color: $base-link-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    @extend %base-focus;

    img {
      box-shadow: 0 0 0 2px $light-blue;
    }
  }
}

// Blockquote
//
// Styles for blockquotes.
//
// Markup:
// <blockquote>Lorem ipsum ...</blockquote>
//
// Styleguide Base.typography
blockquote {
  color: $base-text-color;
  margin: 0;
}
