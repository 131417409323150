// Tables
//
// Styles for tables.
//
// Markup:
// <section class='table {{modifier_class}}'>
//   <header class='table__header'>
//     <span class='table__cell'>Column 1</th>
//   </header>
//   <a ref='#' class='table__row>
//     <span class='table__cell'>Column 1</th>
//   </a>
//   <tfoot class='table__footer'>
//     <span class='table__cell'>Column 1</th>
//   </tfoot>
// </section>
//
// &--standalone - Standalone table.
//
// Styleguide Styleguide.table
.table {
  display: table;
  width: 100%;

  &__header {
    background-color: $grey-150;
    display: table-row;
    line-height: 2;

    &:not(:last-of-type):first-of-type {
      padding-left: $large-spacing;
    }

    &:not(:first-of-type):last-of-type {
      padding-right: $large-spacing;
      text-align: right;
    }
  }

  &__head {
    padding: $small-spacing $base-spacing;
    vertical-align: bottom;
    white-space: normal;
  }

  .table__head.text--center {
    text-align: center;
  }

  .table__head.text--left {
    text-align: left;
  }

  .table__head.text--right {
    text-align: right;
  }

  &__row {
    background-color: $white;
    border-bottom: $base-border;
    box-sizing: border-box;
    color: $base-text-color;
    display: table-row;

    &:hover {
      background-color: $card-hover-color;
      text-decoration: none;
    }

    &:focus {
      transform: scale(1);
    }

    &:last-of-type {
      border-bottom: none;
    }

    &--bold {
      > .table__cell {
        font-weight: bold !important;
      }
    }

    &--border-left {
      border-left: 5px solid $grey-400;
    }

    &--sub-row {
      box-shadow: 0 2px 2px 0 rgba($base-shadow-color, .1);
    }

    &--sub-total-plain {
      background: rgba(0, 0, 0, 0.1);
    }

    &--sub-total {
      border-color: $base-text-color;

      &:hover {
        background-color: $card-hover-color;
        text-decoration: none;
      }

      a {
        color: $base-text-color;
        text-decoration: underline;
      }

      $hover-scale: -5%;

      @for $i from 1 through 0 {
        &--beige-#{(1 - $i)} {
          background-color: nth(nth($beiges, ($i + 1)), 2);

          &:hover {
            background-color: scale-color(nth(nth($beiges, ($i +1)), 2), $lightness: $hover-scale);
            text-decoration: none;
          }
        }

        &--blue-#{(1 - $i)} {
          background-color: nth(nth($blues, ($i + 1)), 2);

          &:hover {
            background-color: scale-color(nth(nth($blues, ($i +1)), 2), $lightness: $hover-scale);
            text-decoration: none;
          }
        }

        &--grey-#{(1 - $i)} {
          background-color: nth(nth($greys, ($i + 3)), 2);

          &:hover {
            background-color: scale-color(nth(nth($greys, ($i + 3)), 2), $lightness: $hover-scale);
            text-decoration: none;
          }
        }

        &--green-#{(1 - $i)} {
          background-color: nth(nth($greens, ($i + 1)), 2);

          &:hover {
            background-color: scale-color(nth(nth($greens, ($i + 1)), 2), $lightness: $hover-scale);
            text-decoration: none;
          }
        }

        &--indigo-#{(1 - $i)} {
          background-color: nth(nth($indigos, ($i + 1)), 2);

          &:hover {
            background-color: scale-color(nth(nth($indigos, ($i +1)), 2), $lightness: $hover-scale);
            text-decoration: none;
          }
        }

        &--orange-#{(1 - $i)} {
          background-color: nth(nth($oranges, ($i + 1)), 2);

          &:hover {
            background-color: scale-color(nth(nth($oranges, ($i +1)), 2), $lightness: $hover-scale);
            text-decoration: none;
          }
        }

        &--purple-#{(1 - $i)} {
          background-color: nth(nth($purples, ($i + 1)), 2);

          &:hover {
            background-color: scale-color(nth(nth($purples, ($i +1)), 2), $lightness: $hover-scale);
            text-decoration: none;
          }
        }

        &--red-#{(1 - $i)} {
          background-color: nth(nth($reds, ($i + 1)), 2);

          &:hover {
            background-color: scale-color(nth(nth($reds, ($i +1)), 2), $lightness: $hover-scale);
            text-decoration: none;
          }
        }
      }
    }

    &--spacer {
      border-top: $small-spacing solid $grey-150;

      @for $i from 1 through 5 {
        &--#{$i} {
          border-top: $i * $small-spacing solid $grey-150;
        }
      }
    }

    &--spacer-bottom {
      border-bottom: $small-spacing solid $grey-150;

      @for $i from 1 through 5 {
        &--#{$i} {
          border-bottom: $i * $small-spacing solid $grey-150;
        }
      }
    }
  }

  &__drawer {
    background-color: $grey-150;
    display: table-row;

    &--inset {
      box-shadow: (
        inset 1px 3px 7px -5px rgba($grey-900, .2),
        inset 1px -3px 7px -5px rgba($grey-900, .2),
      );
    }

    &--inset-top {
      box-shadow: (
        inset 1px 3px 7px -5px rgba($grey-900, .2),
      );
    }
  }

  &__cell {
    @include ellipsis;

    font-weight: normal;
    display: table-cell;
    padding: .8rem $base-spacing;

    &:not(:last-of-type):first-of-type {
      padding-left: $large-spacing;
    }

    &:not(:first-of-type):last-of-type {
      padding-right: $large-spacing;
      text-align: right;
    }

    &.column-lg {
      width: 300px;
    }

    &.column-md {
      width: 200px;
    }

    &.column-sm {
      width: 100px;
    }

    &--component {
      text-align: unset;
      padding: $base-spacing;
    }

    &--padded {
      padding: $base-spacing;
    }
  }

  .table__cell.text--center {
    text-align: center;
  }

  .table__cell.text--left {
    text-align: left;
  }

  .table__cell.text--right {
    text-align: right;
  }

  &__footer {
    display: table-footer-group;
  }

  &--standalone {
    .table__row {
      border-top: $base-border;
    }

    .table__cell {
      &:not(:last-of-type):first-of-type {
        padding-left: $base-spacing;
      }

      &:not(:first-of-type):last-of-type {
        padding-right: $base-spacing;
        text-align: right;
      }
    }
  }

  &--tight {
    .table__cell {
      padding: $small-spacing .8rem;
      text-align: left;

      &:not(:last-of-type):first-of-type {
        padding: $small-spacing .8rem;
      }

      &:not(:first-of-type):last-of-type {
        padding: $small-spacing .8rem;
      }
    }

    .table__head {
      padding: $small-spacing .8rem;
      text-align: left;

      &:not(:last-of-type):first-of-type {
        padding: $small-spacing .8rem;
      }

      &:not(:first-of-type):last-of-type {
        padding-right: .8rem;
      }
    }
  }

  &--x-tight {
    .table__cell {
      padding: $x-small-spacing;
      text-align: left;

      &:not(:last-of-type):first-of-type {
        padding: $x-small-spacing;
      }

      &:not(:first-of-type):last-of-type {
        padding: $x-small-spacing;
      }
    }

    .table__head {
      padding: $x-small-spacing;
      text-align: left;

      &:not(:last-of-type):first-of-type {
        padding: $x-small-spacing;
      }

      &:not(:first-of-type):last-of-type {
        padding-right: $x-small-spacing;
      }
    }
  }

  &--data {
    font-family: Roboto-Regular;

    .table__cell {
      padding: $x-small-spacing;
      text-align: right;

      &:not(:last-of-type):first-of-type {
        padding-left: $x-small-spacing;
      }

      &:not(:first-of-type):last-of-type {
        padding-right: $x-small-spacing;
        text-align: right;
      }

      &--component {
        text-align: unset;
        padding: $base-spacing;
      }
    }

    .table__head {
      padding: $x-small-spacing;
      text-align: right;
    }
  }

  &--no-border {
    .table__row {
      border-bottom: none;
      border-top: none;
    }
  }

  &--column-border {
    border: none;
    border-collapse: collapse;

    .table__head, .table__cell {
      &:not(:last-of-type) {
        border-right: 1px solid $dark;
      }
    }
  }

  &--centered {
    .table__cell {
      text-align: center;

      &:not(:last-of-type):first-of-type {
        padding-left: $base-spacing;
      }

      &:not(:first-of-type):last-of-type {
        padding-right: $base-spacing;
        text-align: center;
      }
    }
  }

  .group-border {
    border-left: 2px solid $grey-300
  }
}

.table__cell {
  &--select {
    overflow-y: visible;
  }

  &--text-left {
    text-align: left !important;
  }

  &--text-right {
    text-align: right !important;
  }

  &--text-center {
    text-align: center !important;
  }

  &--overflow {
    overflow: visible !important;
  }
}
