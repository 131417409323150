// Dropdown
//
// Styles for dropdowns.
//
// Markup:
// <div class='dropdown{{modifier_class}}'>
//   <button class='dropdown__btn'>
//     <span>Selected</span>
//     <svg class='icon--drop-down'></svg>
//  </button>
//   <ul class='dropdown__options'>
//     <li class='dropdown__option'>Option</li>
//   </ul>
// </div>
//
// --flat - Flat dropdown.
//
// Styleguide Styleguide.dropdowns
.dropdown {
  @include options;

  display: inline-block;
  min-width: 14rem;
  width: auto;

  &__btn {
    @include position(relative, null null null null);

    background-color: $white;
    color: $base-text-color;
    line-height: 1.2;
    margin-bottom: 0;
    padding: $small-spacing 1rem;
    text-align: left;

    span {
      margin-right: $large-spacing;
    }

    .icon {
      @include position(absolute, .45rem .5rem null null);
    }

    &.is-active {
      background-color: $focus-button-background;
      box-shadow: $focus-box-shadow-inset;

      &:hover {
        box-shadow: $focus-box-shadow-inset;
      }
    }
  }
}

.dropdown-menu {
  @include position(relative, null null null null);

  display: inline-block;

  &:after {
    @include position(absolute, null null null null);

    content: "";
    display: block;
    height: $small-spacing / 2;
    width: 100%;
  }

  &__content {
    @include position(absolute, null null null null);

    border-radius: $base-border-radius;
    display: none;
    height: auto;
    margin-top: $small-spacing / 2;
    min-width: 200px;
    overflow: hidden;
    width: 100%;
    z-index: 1;
  }

  &__item {
    background-color: $white;
    border-bottom: $base-border;
    color: map-get($text-colors, 'grey');
    cursor: pointer;
    display: block;
    padding: .5rem;
    width: 100%;

    &:hover {
      background-color: $card-hover-color;
    }

    &:last-of-type {
      border-bottom: 0;
    }

    > * {
      display: block;
      width: auto;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &:hover {
    .dropdown-menu__content {
      display: block;
    }
  }

  &--right {
    &:after, .dropdown-menu__content {
      right: 0;
    }
  }
}
