// Framework
@import '~bourbon/core/bourbon';
@import '~bourbon-neat/core/neat';

// Base
@import './base/base';

// Styleguide inputs
@import './styleguide/inputs';

$base-spacing: 2rem;
$small-spacing: .25rem;

.body {
  background-color: $grey-800;
}

.container {
  background-color: $white;
  box-shadow: (
    inset 0 -3px 0 0 $grey-150,
    0 1px 2px 0 rgba($grey-800, .22),
    0 2px 4px 0 rgba($grey-800, .1),
    0 3px 6px 0 rgba($grey-800, .1)
  );
  box-sizing: border-box;
  height: 100vh;
  margin: 0 auto 0 10%;
  padding: 4rem;
  width: 50%;

  h1 {
    margin-bottom: $base-spacing;
  }

  .fieldset {
    margin-bottom: $base-spacing;

    // scss-lint:disable QualifyingElement
    > input[type=checkbox] {
      vertical-align: baseline;
    }

    > input + label {
      margin-left: 0;
      vertical-align: baseline;
    }
  }

  label {
    margin-bottom: $small-spacing;
  }

  #{$all-text-inputs} {
    margin-bottom: $base-spacing;
    padding-left: 1rem;
    width: 65%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  // scss-lint:disable QualifyingElement
  input[type=submit] {
    margin-right: $base-spacing;
  }
}
