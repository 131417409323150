// Card content mixin
//
// Card content styles.
//
// --padded - Card content without padding.
// --list - Pads each child and places borders between.
// --drawer - Content with grey background.
//
// Styleguide Base.mixins
@mixin card-content($modifiers: true) {
  cursor: default;

  > :not(.card) {
    border: 0;
    border-radius: 0;

    > a {
      @extend %card-hover;
    }
  }

  > .card__content__header {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: .5rem 1rem;

    > .heading {
      margin: 0;
    }
  }

  @if ($modifiers) {
    &--padded {
      padding: $card-spacing;

      > .is-empty {
        padding: 0;
      }
    }

    &--padded-small {
      padding: $x-small-spacing $small-spacing;

      > .is-empty {
        padding: 0;
      }
    }

    &--list {
      > .card__content__header {
        border-bottom: $base-border;
      }

      > :not(.card):not(.is-empty):not(.card__content__header) {
        @extend %card-hover;

        border-bottom: $base-border;
        color: $base-text-color;
        min-height: 48px;
        padding: .5rem 1rem;

        &:last-of-type {
          border-bottom: 0;
        }
      }
    }

    &--drawer {
      background-color: $grey-150;

      .card__overflow {
        overflow-x: auto;
        white-space: nowrap;

        &__item {
          display: inline-block;
          margin: 1rem ($small-spacing / 2) $small-spacing 0;

          &:first-of-type {
            margin-left: 1rem;
          }

          &:last-of-type {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
