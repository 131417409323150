// Tables
//
// Styles for tables.
//
// Markup:
// <table>
//   <thead>
//     <tr>
//       <th>Column 1</th>
//     </tr>
//   </thead>
//   <tbody>
//     <tr>
//       <td>Column 1</td>
//     </tr>
//   </tbody>
//   <tfoot>
//     <tr>
//       <td>Column 1</td>
//     </tr>
//   </tfoot>
// </table>
//
// Styleguide Base.table
.table {
  border-collapse: collapse;
  table-layout: auto;
  text-align: left;
  width: 100%;
}
