// Button sizes
//
// Styles for button sizes
//
// Styleguide Styleguide.buttons
.btn {
  &--sm {
    font-size: $font-size-sm;
    font-weight: 400;
    padding: ($small-spacing / 2) $small-spacing;
  }

  &--text {
    font-size: $font-size-xs;
    font-weight: 300;
    padding: 0;
  }
}
