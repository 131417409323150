// Unordered lists
//
// Styles for unordered lists.
//
// Markup:
// <ul><li>Item 1</li></ul>
//
// Styleguide Base.lists
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

// Unordered lists
//
// Styles for unordered lists.
//
// Markup:
// <ol><li>Item 1</li></ol>
//
// Styleguide Base.lists
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

// Definitoon lists
//
// Styles for definitoon lists.
//
// Markup:
// <dl>
//   <dt>Term</dt>
//   <dd>Definition</dd>
// </dl>
//
// Styleguide Base.lists
dl {
  margin: 0;
  text-align: left;

  dt {
    color: map-get($text-colors, 'grey-400');
    font-size: $font-size-sm;
    margin: 0;
  }

  dd {
    margin: 0;
  }
}
