// Cards
//
// Styles for cards.
//
// Markup:
// <div class='card{{modifier_class}}'>
//    <header class='card__header{{modifier_class}}'>
//      <h1>Card header</h1>
//      <a href='#' class='card__header__toggle'><svg></svg></a>
//      <a href='#' class='btn btn--tertiary btn--icon'>
//        <svg></svg>
//        Add resource
//      </a>
//    </header>
//    <section class='card__content{{modifier_class}}'>Card content</section>
//    <section class='card__footer'>Card content</section>
// </div>
//
// --buffer-bottom - Card with larger margin bottom.
// --basic - Basic card.
// --expanded - Expanded card.
//
// .dp--0 - Card elevation 0.
// .dp--1 - Card elevation 1.
// .dp--2 - Card elevation 2.
// .dp--3 - Card elevation 3.
//
// .card__header--with-thumbnail - Card header with a thumbnail.
//
// card__content--box-list - Pads each child and places borders between.
// card__content--drawer - Content with grey background.
// card__content--padded - Card content without padding.
// is-collapsed - Collapsed card state.
//
// Styleguide Styleguide.cards
.card {
  @include card;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__header + .card__footer {
    border-top: 0;
  }

  &--allow-overflow {
    overflow: visible;
  }

  &--allow-overflow-x {
    overflow: unset;
    overflow-x: visible;
  }

  &--allow-overflow-y {
    overflow: unset;
    overflow-y: visible;
  }

  &--buffer-bottom {
    margin-bottom: $large-spacing;
  }

  &.is-collapsed {
    .card__header {
      border-bottom: 0;
    }
  }

  &.dp {
    @include elevation;
  }

  &--basic {
    @include card($parts: false, $states: false);

    color: $base-text-color;
    display: block;
    margin-bottom: 0;
    padding: $card-spacing;

    &:hover {
      text-decoration: none;

      > .card__header {
        text-decoration: underline;
      }
    }

    &.dp {
      @include elevation;
    }

    .card__header {
      display: block;
      border: none;
    }

    .card__content {
      border: none;
      color: map-get($text-colors, 'grey');
      padding: 0;
    }
  }

  &--expanded {
    > .card__header {
      @include card-header($parts: false, $modifiers: false);

      background-color: $white;
      border-bottom: $card-border;

      h1 {
        line-height: 1.2;
      }

      .btn--tertiary {
        padding: 1rem;
      }

      + .card__content {
        background-color: $grey-150;
        padding: $large-spacing;
      }

      + .card__content--white {
        background-color: $white;
      }
    }

    .card__footer {
      padding: 1rem;
    }

    &.card--condensed {
      > .card__header {
        margin-bottom: 0;
      }

      .card__content {
        padding: 0;
      }
    }
  }
}
