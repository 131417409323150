// Toasts
//
// Toast styles with action colors.
//
// Markup:
// <div class='toast {{modifier_class}}'>
//   <p>Toast message</p>
// </div>
//
// --primary - Primary badge
// --secondary - Secondary badge
// --info - Info badge
// --success - Success badge
// --warning - Warning badge
// --danger - Danger badge
// --light - Light badge
// --dark - Dark badge
//
// Styleguide Styleguide.toasts
.toast {
  @each $name, $value in $action-colors {
    @include position(absolute, $base-spacing null null 0);

    animation: fadeOut 5s;
    animation-fill-mode: forwards;
    pointer-events: none;
    text-align: center;
    width: 100%;

    > p {
      border-radius: $base-border-radius;
      color: $white;
      display: inline-block;
      font-size: $base-font-size;
      font-weight: 400;
      line-height: 1.2;
      margin: 0;
      min-width: 200px;
      padding: $small-spacing $large-spacing;
      transition: visibility 0s 2s, opacity 2s linear;
      width: auto;

      box-shadow: (
        inset 0 -1px 2px 0 transparentize($base-shadow-color, .85),
        0 1px 1px 0 transparentize($base-shadow-color, .85),
        0 2px 3px 0 transparentize($base-shadow-color, .9),
        0 3px 9px 0 transparentize($base-shadow-color, .8)
      );
    }

    &--#{$name} {
      > p {
        background-color: $value;

        @if $name == 'warning' {
          color: $beige;
        }
      }
    }
  }
}

// Fade out animation
@keyframes fadeOut {
  0% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: 0; }
}
