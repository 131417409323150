// Tooltip
//
// Styles for tooltips.
//
// Markup:
// <span class='tooltip {{modifier_class}}' data-text='Text to show'>
// </span>
//
// Styleguide Styleguide.tooltips
.tooltip {
  background: transparent;
  overflow: auto;
  position: relative;

  &:before {
    @include position(absolute, 50% null null 100%);

    background: $dark;
    border-radius: $base-border-radius;
    box-shadow: (
      0 1px 1px 0 rgba($grey-900, .15),
      0 2px 3px 0 rgba($grey-900, .1)
    );
    color: $white;
    content: attr(data-text);
    display: none;
    margin-left: $base-spacing;
    max-width: 500px;
    min-width: 100px;
    padding: $small-spacing;
    text-align: left;
    text-indent: 0;
    transform: translateY(-50%);
    width: max-content;
    white-space: pre-line;
  }

  &:hover:before {
    display: block;
    z-index: 2;
  }

  &--above {
    &:before {
      @include position(absolute, 0% 50% null null);

      left: auto;
      margin: 0 0 $base-spacing 0;
      top: auto;
    }
  }

  &--below {
    &:before {
      @include position(absolute, 100% 50% null null);

      left: auto;
      margin: $base-spacing 0 0 0;
      top: auto;
    }
  }

  &--left {
    &:before {
      @include position(absolute, 50% 100% null null);

      left: auto;
      margin: 0 $base-spacing 0 0;
      top: auto;
    }
  }

  &--above-align {
    &:before {
      @include position(absolute, 0% null null 0%);

      left: auto;
      margin: 0 0 $base-spacing 0;
      top: auto;
    }
  }

  &--below-align {
    &:before {
      @include position(absolute, 100% null null 0%);

      left: auto;
      margin: $base-spacing 0 0 0;
      top: auto;
    }
  }
}

.table__cell--tooltip {
  overflow: visible;
}
