// Masonry grid
//
// Creates a responsive masonry grid with up to 3 columns
//
// Markup:
// <div class='masonry masonry--{{modifier_class}}'>
//   <div class='masonry__item masony__item--{{modifier_class}}'>Item</div>
// </div>
//
// .grid--condensed - Reduces gutter of grid.
//
// -1 - 1 column.
// -2 - 2 columns.
// -3 - 3 columns.
//
// Styleguide Base.helpers

.masonry-2 {
  display: flex;
  flex-flow: column nowrap;

  .masonry__item {
    width: 100%;
    padding-bottom: $base-spacing;
  }

  @media only screen and (min-width: 1700px) {
    align-content: space-between;
    display: flex;
    flex-flow: column wrap;

    .masonry__item {
      box-sizing: border-box;
      width: 50%;
      padding-bottom: $base-spacing;
    }

    .masonry__item:nth-child(2n+1) {
      order: 1;
      padding-right: $base-spacing;
    }

    .masonry__item:nth-child(2n) {
      order: 2;
      padding-right: 0;
    }

    &::after {
      content: "";
      flex-basis: 100%;
      width: 0;
      order: 1;
    }
  }
}

$sizes: 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400;

@each $size in $sizes {
  .masonry-sm--#{$size} {
    height: $size + 0px;
  }
}

@media only screen and (max-width: 1700px) {
  @each $size in $sizes {
    .masonry--#{$size} {
      height: $size + 0px;
    }
  }
}
