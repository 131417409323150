// Flex container mixin
//
// Base flex container mixin
//
// --justify-start - Justify content start.
// --justify-end - Justify content end.
// --justify-center - Justify content center.
// --justify-between - Justify content space between.
// --justify-around - Justify content space around.
// --align-stretch - Stretch items within container.
// --align-center - Align items to the center opposite flex direction.
// --align-baseline - Align items to their baseline.
// --align-start - Align items to the start.
// --align-end - Align items to the end.
//
// Styleguide Base.mixins
@mixin flex-container(
  $direction: row,
  $wrap: wrap,
  $justify: flex-start,
  $align: center
) {
  align-items: $align;
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  justify-content: $justify;

  &__break {
    flex-basis: 100%;
    width: 0;
  }

  &--gap {
    > .flex__item {
      margin-right: $base-spacing;
    }

    > .flex__item:last-of-type {
      margin-right: 0;
    }
  }

  &--gap-sm {
    > .flex__item {
      margin-right: $small-spacing;
    }

    > .flex__item:last-of-type {
      margin-right: 0;
    }
  }

  &--space {
    > .flex__item {
      margin-bottom: $base-spacing;
    }
  }

  &--column {
    flex-direction: column;

    > div {
      margin-bottom: $base-spacing;
    }
  }

  &--wrap {
    flex-wrap: wrap;
    margin-top: -$base-spacing;

    > .flex__item {
      margin-top: $base-spacing;
    }
  }

  &--wrap-sm {
    flex-wrap: wrap;
    margin-top: -$small-spacing;

    > .flex__item {
      margin-top: $small-spacing;
    }
  }

  &--no-wrap {
    flex-wrap: nowrap;
  }

  &--justify-start {
    justify-content: flex-start;
  }

  &--justify-end {
    justify-content: flex-end;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-between {
    justify-content: space-between;
  }

  &--justify-around {
    justify-content: space-around;
  }

  &--align-stretch {
    align-items: stretch;
  }

  &--align-center {
    align-items: center;
  }

  &--align-baseline {
    align-items: baseline;
  }

  &--align-start {
    align-items: flex-start;
  }

  &--align-end {
    align-items: flex-end;
  }

  &--column {
    flex-direction: column;
  }

  &--bordered {
    border: $large-border;
    border-radius: $base-border-radius;

    > .flex__item {
      border-right: $large-border;

      &:last-of-type {
        border-right: none;
      }
    }
  }
}
