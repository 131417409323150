// Border helper
//
// Add border to element.
//
// Markup:
// <div class='border{{modifier_class}}'>Content</div>
//
// --top - Top border.
// --bottom - Bottom border.
// --left - Left border.
// --right - Right border.
// --primary - Primary border.
// --secondary - Secondary border.
// --info - Info border.
// --success - Success border.
// --warning - Warning border.
// --danger - Danger border.
// --light - Light border.
// --dark - Dark border.
// --grey - Grey border.
// --blue - Blue border.
// --pink - Pink border.
// --beige - Beige border.
// --purple - Purple border.
// --red - Red border.
// --green - Green border.
// --indigo - Indigo border.
// --light-blue - Light blue border.
// --white - White border.
// --black - Black border.
// --grey-100 - Grey 100 border.
// --grey-150 - Grey 150 border.
// --grey-200 - Grey 200 border.
// --grey-250 - Grey 250 border.
// --grey-300 - Grey 300 border.
// --grey-350 - Grey 350 border.
// --grey-400 - Grey 400 border.
// --grey-500 - Grey 500 border.
// --grey-600 - Grey 600 border.
// --grey-700 - Grey 700 border.
// --grey-800 - Grey 800 border.
// --grey-900 - Grey 900 border.
//
// Styleguide Base.helpers
.border {
  border: $base-border;

  &--none {
    border: none !important;

    &--top {
      border-top: none !important;
    }

    &--bottom {
      border-bottom: none !important;
    }

    &--left {
      border-left: none !important;
    }

    &--right {
      border-right: none !important;
    }
  }

  &--top {
    border-top: $base-border;
  }

  &--bottom {
    border-bottom: $base-border;
  }

  &--left {
    border-left: $base-border;
  }

  &--right {
    border-right: $base-border;
  }

  @each $name, $value in $nav-colors {
    &--#{$name} {
      border-bottom: 2px solid $value !important;
    }
  }

  @each $name, $value in $text-colors {
    &--#{$name} {
      border: 2px solid $value !important;

      &--top {
        border-top: 1px solid $value;
      }

      &--bottom {
        border-bottom: 1px solid $value;
      }

      &--left {
        border-left: 1px solid $value;
      }

      &--right {
        border-right: 1px solid $value;
      }
    }
  }
}
