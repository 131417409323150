// Pedigree
//
// Styling for N-generational pedigree
//
// Markup:
// <div class='pedigree'>
//   <div class='pedigree__generation'>
//     <div class='pedigree__horse'>
//       <div class='pedigree__horse_info'>Horse</div>
//     </div>
//     <div class='pedigree__horse'>
//       <div class='pedigree__horse_info'>Horse 2</div>
//     </div>
//   </div>
// </div>
//
// Styleguide Styleguide.front_end
.pedigree {
  @include flex-container(
    $wrap: nowrap,
    $justify: space-between,
    $align: stretch
  );

  > div:last-child {
    @include border-style(null solid null null);
    @include border-width(null 1px null null);
  }

  &__generation {
    @include border-style(solid null null null);
    @include border-width(1px null null null);
    @include flex-container(
      $direction: column,
      $justify: space-around,
      $align: stretch
    );

    flex: auto;

    > div:nth-child(odd) {
      background: $grey-150;
    }
  }

  &__horse {
    @include border-style(null null solid solid);
    @include border-width(null null 1px 1px);
    @include flex-container(
      $direction: column,
      $justify: space-around,
      $align: stretch
    );

    flex: auto;
    padding-left: $small-spacing;
  }

  &__horse_info {
    flex: 0 0 auto;
  }
}

.pedigree__horse {
  $ped-colors: (
    $red-300,
    $blue-300,
    $green-300,
    $purple-300,
    $pink-300,
    $beige-300,
    $red-500,
    $blue-600,
    $green-500,
    $purple-500,
    $pink-500,
    $beige-700
  );

  @for $i from 1 through length($ped-colors) {
    &--color-#{$i} {
      &::after {
        background: nth($ped-colors, $i);
        content: "";
        height: 100%;
        margin-left: auto;
        width: 0.5rem;
      }
    }
  }
}
