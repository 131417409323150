// Block lists
//
// Styles for block list.
//
// Markup:
// <ul class='vertical-list'>
//   <li class='vertical-list__item {{modifier_class}}'>
//     <div class='vertical-list__term'>Title</div>
//     <div class='vertical-list__value'>Value</div>
//   </li>
// </ul>
//
// Styleguide Styleguide.lists
.vertical-list {
  background-color: $white;
  border: $base-border;
  border-radius: ($base-border-radius * 2);

  &__item {
    @include flex-container;

    border-bottom: $base-border;
    color: $base-text-color;
    line-height: 32px;
    padding: .4rem 1rem;

    &:last-of-type {
      border-bottom: 0;
    }

    &--total {
      border-top: 2px solid $base-border-color;
      line-height: 48px;
    }

    &--simple-total {
      font-weight: bold;
    }
  }

  &__term {
    @include flex-item;
  }

  &__value {
    @include flex-item;

    text-align: right;
  }
}
