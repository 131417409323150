// Flex box
//
// Creates a flex box for single row with gutters
//
// Markup:
// <div class='flex-box{{modifier_class}}'>
//   <div class='flex-box__item {{modifier_class}}'>Item1</div>
//   <div class='flex-box__item {{modifier_class}}'>Item2</div>
// </div>
//
// Styleguide Styleguide.flex_boxes
.flex-box {
  @include flex-container($wrap: nowrap, $justify: space-between);

  &__item {
    @include flex-item(false, $base-spacing);
  }

  &--list {
    @include flex-container($wrap: nowrap, $justify: flex-start);

    background-color: $white;
    border: $base-border;
    border-radius: $base-border-radius;
    width: 100%;

    .flex-box__item {
      @include flex-item(false, $base-spacing);

      border-right: $base-border;
      color: map-get($text-colors, 'grey-900');
      flex: 1;
      padding: $base-spacing 0;

      &:first-of-type {
        padding-left: $base-spacing;
      }

      &:last-of-type {
        border-right: 0;
      }

      &--fixed  {
        padding: $base-spacing;
        text-align: center;
      }
    }

    .flex-box__value {
      display: block;
      font-size: $font-size-lg;
      line-height: 1.2;
    }

    .flex-box__term {
      color: map-get($text-colors, 'grey');
      display: block;
      font-size: $base-font-size;
      line-height: 1;
    }
  }
}
