// Matrix
//
// Creates a grid-like matrix where elements can span
// multiple columns and rows
//
// Markup:
// <div class='matrix'>
//   <div class='matrix__item {{modifier}}'>Item</div>
// </div>
//
// --w-1 - 1 column.
// --w-2 - 2 columns.
// --w-3 - 3 columns.
// --w-4 - 4 columns.
// --w-5 - 5 columns.
// --w-6 - 6 columns.
// --w-7 - 7 columns.
// --w-8 - 8 columns.
// --w-9 - 9 columns.
// --w-10 - 10 columns.
// --w-11 - 11 columns.
// --w-12 - 12 columns.
//
// Styleguide Styleguide.matrix
.matrix {
  align-items: stretch;
  display: grid;
  justify-items: stretch;
  grid-column-gap: $base-spacing;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: $base-spacing;
  margin-bottom: $base-spacing;

  &__item {
    grid-column-start: auto;
    grid-row-start: auto;

    @for $i from 1 through 12 {
      &--c-#{$i} {
        grid-column-start: $i;
      }

      &--w-#{$i} {
        grid-column-end: span $i;
      }

      &--r-#{$i} {
        grid-row-start: $i;
      }

      &--h-#{$i} {
        grid-row-end: span $i;
      }
    }
  }
}
