// Metric
//
// Default metric display
//
// Markup:
// <div class='metric{{modifier_class}}'>
//   <div class='metric__value'>5</div>
//   <div class='metric__label'>Horses</div>
// </div>
//
// &--large - Large
//
// Styleguide Styleguide.metrics
.metric {
  @include flex-container($direction: column, $wrap: no-wrap, $justify: space-between);

  background-color: $white;
  border-radius: $base-border-radius;

  &--no-border {
    border: none;
  }

  &__header {
    border-bottom: $card-border;
    padding: $base-spacing;
    width: 100%;

    > h1 {
      font-size: modular-scale(1, $base-font-size, $minor-third);
      line-height: 1.2;
      margin-bottom: 0;
    }
  }

  &__content {
    padding: $base-spacing;
  }

  &__value {
    @include flex-item($wrappable: false);

    border-radius: .25rem;
    color: $base-text-color;
    font-weight: bold;
    font-size: $font-size-xxxl;
    line-height: 32px;
    padding: $base-spacing;
  }

  &__label {
    @include flex-item($wrappable: false, $fixed: true);

    padding: $small-spacing;
    padding-top: 0;
  }

  &.tooltip {
    background-color: $white;
    overflow: visible;

    &:hover {
      background-color: $card-hover-color;
    }
  }

  &--table {
    height: 100%;

    .metric__content {
      height: 100%;
      width: 100%;
    }
  }

  &--clear {
    background-color: transparent;
  }

  &--small {
    padding-top: $x-small-spacing;

    .metric__value {
      font-size: $font-size-lg;
      line-height: $font-size-lg;
      padding: $x-small-spacing;
    }

    .metric__label {
      font-size: $font-size-sm;
      padding: $x-small-spacing;
      padding-top: 0;
    }
  }

  &--x-small {
    .metric__value {
      font-size: $font-size-sm;
      line-height: $font-size-sm;
      padding: $x-small-spacing;
    }

    .metric__label {
      font-size: $font-size-xs;
      padding: $x-small-spacing;
      padding-top: 0;
    }
  }

  @each $name, $value in $action-colors {
    &--badge-#{$name} {
      .metric__value {
        background-color: $value;
        color: $white;
      }
    }
  }

  .table {
    font-size: $font-size-lg;
    text-align: center;
    width: 100%;
  }
}

a.metric {
  color: $base-text-color;

  &:hover {
    background-color: $card-hover-color;
    cursor: pointer;
  }
}
