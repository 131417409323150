// Layout
$main-menu-size: 64px;
$sidebar-size: 304px;
$notices-size: 512px;
$aside-size: 350px;
$footer-size: 3.5rem;
$ratio: 1.2;

// Typography
$base-font-family: Rubik-Regular;
$heading-font-family: Rubik-Light;
$base-font-size: 16px !default;
$font-size-xxxl: 32px !default;
$font-size-xxl: 26px !default;
$font-size-xl: 23px !default;
$font-size-lg: 20px !default;
$font-size-md: 18px !default;
$font-size-sm: 13px !default;
$font-size-xs: 12px !default;
$font-size-xxs: 10px !default;

// Text sizes map
$text-sizes: (
  'xxl': $font-size-xxl,
  'xl': $font-size-xl,
  'lg': $font-size-lg,
  'md': $font-size-md,
  'sm': $font-size-sm,
  'xs': $font-size-xs,
  'xxs': $font-size-xxs
);

// Line height
$base-line-height: 1.5 !default;
$heading-line-height: 1.2 !default;

// Text
$base-text-color: #262c33;
$base-link-color: $blue-600;

// Spacing
$base-spacing: $ratio + 0rem !default;
$small-spacing: $base-spacing / 2 !default;
$x-small-spacing: $base-spacing / 4 !default;
$large-spacing: ($base-spacing * 2);

// Borders
$base-border-radius: 3px !default;
$base-border-color: #e2e5e9 !default;
$base-border: 1px solid $base-border-color !default;
$large-border: 2px solid $base-border-color !default;
$sidebar-dropdown-border: 1px solid $grey-300;

// Z-index
$base-z-index: 0 !default;

// Focus
$focus-box-shadow: 0 0 0 2px $light-blue !default;
$focus-box-shadow-inset: inset 0 0 0 2px $light-blue !default;
$focus-button-background: #ecf3f8;

// Shadows
$base-shadow-color: $base-text-color;

// Icons
$icon-size: 24px;

// Inputs
$placeholder-color: rgba($base-shadow-color, .5);

// Sizing
$base-min-height: 100px;

// Cards
$card-spacing: 1rem $base-spacing;
$card-border: 1px solid $grey-200;
$card-hover-color: #ebf0f4;

// Grids
$neat-grid: (columns: 12, gutter: $base-spacing);
$neat-grid-lg: (columns: 12, gutter: $large-spacing);
$neat-grid-sm: (columns: 12, gutter: $small-spacing);

// Image sizes
$xs-image-size: 32px;
$sm-image-size: 48px;
