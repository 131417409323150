// Badges
//
// Different badge styles in multiple sizes and colors.
//
// Markup:
// <span class='badge {{modifier_class}}'>Text</span>
//
// --primary - Primary badge.
// --secondary - Secondary badge.
// --info - Info badge.
// --success - Success badge.
// --warning - Warning badge.
// --danger - Danger badge.
// --light - Light badge.
// --dark - Dark badge.
// --grey - Grey badge.
// --blue - Blue badge.
// --pink - Pink badge.
// --beige - Beige badge.
// --purple - Purple badge.
// --red - Red badge.
// --green - Green badge.
// --indigo - Indigo badge.
// --light-blue - Light blue badge.
// --white - White badge.
// --black - Black badge.
// --grey-100 - Grey 100 badge.
// --grey-150 - Grey 150 badge.
// --grey-200 - Grey 200 badge.
// --grey-250 - Grey 250 badge.
// --grey-300 - Grey 300 badge.
// --grey-350 - Grey 350 badge.
// --grey-400 - Grey 400 badge.
// --grey-500 - Grey 500 badge.
// --grey-600 - Grey 600 badge.
// --grey-700 - Grey 700 badge.
// --grey-800 - Grey 800 badge.
// --grey-900 - Grey 900 badge.
// --pill - Pill badge.
// --circle - Circular badge.
// --circle-xs - Extra small circular badge.
// --xxs - Extra small badge.
// --md - Medium badge.
// --lg - Large badge.
// --xl - Extra large badge.
//
// Styleguide Styleguide.badges
.badge {
  border: 0;
  border-radius: .25rem;
  box-sizing: border-box;
  display: inline-block;
  font-size: $font-size-xs;
  font-weight: 400;
  line-height: 1;
  padding: .25rem .4rem;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;

  @each $name, $value in $text-colors {
    &--#{$name} {
      background-color: $value;
      color: contrast-switch(darken($value, 25%));

      @if $name == 'white' {
        box-shadow: 0 0 0 1px $base-border-color;
      }

      @if $name == 'secondary' {
        color: map-get($text-colors, 'white');

        > .text--grey {
          color: map-get($text-colors, 'white');
        }
      }
    }
  }

  &--pill {
    border-radius: 1rem;

    &.badge--lg {
      padding: .3rem .8rem;
    }
  }

  &--circle {
    $size: 48px;

    border-radius: 50%;
    font-size: $font-size-lg;
    height: $size;
    line-height: 1.9;
    text-align: center;
    width: $size;

    &-xs {
      font-size: $font-size-xs;
      height: auto;
      line-height: 1;
      vertical-align: middle;
      width: auto;
    }
  }

  &--xxs {
    font-size: $font-size-xxs;
    line-height: 1;
  }

  &--md {
    font-size: $font-size-sm;
    padding: .4rem .8rem;
  }

  &--lg {
    font-size: $base-font-size;
    padding: .5rem 1rem;
  }

  &--xl {
    font-size: $font-size-lg;
    padding: .6rem 1rem;
  }

  &--xxl {
    font-size: $font-size-lg;
    padding: .9rem 1.2rem;
  }
}
