// Drodpwn and select options mixin
//
// Drodpwn and select shared styles.
//
// Styleguide Base.mixins
@mixin options($options: true, $option: true) {

  @if ($options) {
    &__options {
      @include position(absolute, null null null null);

      border-radius: $base-border-radius;
      width: 100%;
      z-index: 1;
    }

    &[aria-expanded='true'] div[id$='__options'] {
      @extend %base-shadow;
    }
  }

  @if ($option) {
    &__option {
      background-color: $white;
      border-bottom: $base-border;
      color: map-get($text-colors, 'grey');
      cursor: pointer;
      display: block;
      padding: .5rem 1rem;
      width: 100%;

      &.is-active,
      &[aria-selected='true'] {
        background-color: $card-hover-color;
      }

      &:first-of-type {
        border-top-left-radius: $base-border-radius;
        border-top-right-radius: $base-border-radius;
      }

      &:last-of-type {
        border-bottom: 0;
        border-bottom-left-radius: $base-border-radius;
        border-bottom-right-radius: $base-border-radius;
      }

      &__row {
        @include flex-container($justify: space-between);
      }

      &__title {
        @include flex-item($wrappable: false);

        color: map-get($text-colors, 'black');
      }

      &__subtitle {
        @include flex-item($wrappable: false, $fixed: true);

        font-size: $font-size-xs;
      }
    }
  }
}
