// Card footer mixin
//
// Card footer styles.
//
// Styleguide Base.mixins
@mixin card-footer() {
  border-top: $card-border;
  color: rgba($base-text-color, .5);

  > a {
    color: $base-link-color;
    display: block;
    padding: 1rem $base-spacing;
  }

  &--drawer {
    .link {
      padding: ($small-spacing / 2) $base-spacing;
    }
  }
}
