// Buffers
//
// Buffer types with chainable modifiers.
//
// Markup:
// <div class='buffer--{{modifier_class}}'></div>
//
// --1 - 1 rem spacing.
// --2 - 2 rem spacing.
// --3 - 3 rem spacing.
// --4 - 4 rem spacing.
// --5 - 5 rem spacing.
// --6 - 6 rem spacing.
// --top - Base top buffer.
// --bottom - Base bottom buffer.
// --left - Base left buffer.
// --right - Base right buffer.
// --md - Base buffer.
// --sm - Small buffer.
// --xs - Extra small buffer.
//
// Styleguide Base.helpers
.buffer {
  @for $i from 0 through 6 {
    &--#{$i} {
      margin: ($i * 1rem) 0 !important;

      &--all {
        margin: $i * 1rem !important;
      }

      &--top {
        margin-top: $i * 1rem !important;
      }

      &--bottom {
        margin-bottom: $i * 1rem !important;
      }

      &--left {
        margin-left: $i * 1rem !important;
      }

      &--right {
        margin-right: $i * 1rem !important;
      }

      &--sides {
        margin-right: $i * 1rem !important;
        margin-left: $i * 1rem !important;
      }
    }
  }

  &--top {
    margin-top: $base-spacing;
  }

  &--bottom {
    margin-bottom: $base-spacing;
  }

  &--left {
    margin-left: $base-spacing;
  }

  &--md {
    margin: $base-spacing;

    &--top {
      margin-top: $base-spacing;
    }

    &--bottom {
      margin-bottom: $base-spacing;
    }

    &--left {
      margin-left: $base-spacing;
    }

    &--right {
      margin-right: $base-spacing;
    }
  }

  &--right {
    margin-right: $base-spacing;
  }

  &--sm {
    margin: $small-spacing;

    &--top {
      margin-top: $small-spacing;
    }

    &--bottom {
      margin-bottom: $small-spacing;
    }

    &--left {
      margin-left: $small-spacing;
    }

    &--right {
      margin-right: $small-spacing;
    }
  }

  &--xs {
    margin: ($small-spacing / 2);

    &--top {
      margin-top: ($small-spacing / 2);
    }

    &--bottom {
      margin-bottom: ($small-spacing / 2);
    }

    &--left {
      margin-left: ($small-spacing / 2);
    }

    &--right {
      margin-right: ($small-spacing / 2);
    }
  }

  &--negative--right {
    margin-right: -.25rem;
  }
}
