// Display
//
// Display types.
//
// Markup:
// <span class='display{{modifier_class}}'>Content</span>
//
// --block - Display block.
// --inline-block - Display inline block.
// --inline - Display inline.
// --flex - Display flex.
//
// Styleguide Base.helpers
.display {
  &--block {
    display: block;
  }

  &--inline-block {
    display: inline-block;
  }

  &--inline {
    display: inline;
  }

  &--flex {
    display: flex;
  }
}
