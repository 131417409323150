// Fieldset
//
// Styles for form fieldsets.
//
// Markup:
// <fieldset class='fieldset {{modifier_class}}'></fieldset>
//
// Styleguide Styleguide.forms
.fieldset {
  .hint {
    display: block;
    font-size: $font-size-xs;
    line-height: 1;
    margin-top: $small-spacing;

    &--block {
      display: block;
      font-size: $font-size-sm;
      line-height: 1.2;
      margin-bottom: $base-spacing;

      &:first-of-type {
        margin-top: $small-spacing;
      }
    }
  }
}
