// Flex item mixin
//
// --fixed - Element wont grow or shrink.
//
// Styleguide Base.mixins
@mixin flex-item($wrappable: true, $gutter: 0, $fixed: false) {
  flex: 1;

  @if ($gutter > 0) {
    margin-right: $gutter;
  }

  @if (not $wrappable) {
    &:last-of-type {
      margin-right: 0;
    }
  }

  @if ($fixed) {
    flex: 0 0 auto;
  }

  > * {
    vertical-align: middle;
  }

  &--normal {
    flex: 0 1 auto;
  }

  &--grow {
    flex: 1 0 auto;
  }

  &--fill {
    flex: 1 0 100%;
  }

  &--fixed {
    flex: 0 0 auto;
  }

  &--align-start {
    align-self: flex-start;
  }

  &--align-end {
    align-self: flex-end;
  }

  &--align-center {
    align-self: center;
  }

  &--align-baseline {
    align-self: baseline;
  }

  &--align-stretch {
    align-self: stretch;
  }
}
