// Inputs
//
// Styles for form inputs.
//
// Markup:
// <input type='text' class='input{{modifier_class}}' />
//
// :hover - Input hover.
// :focus - Input focus.
// :disabled - Input disabled.
//
// .is-success - Input succes.
// .is-error - Input error.
//
// --flat - Flat input.
// --short - Short input.
// --short - Fits width to size of contents.
//
// Styleguide Styleguide.inputs
.input {
  @extend %base-shadow;

  background-color: $white;
  color: $grey-900;
  display: block;
  min-width: 14rem;
  padding: $small-spacing 1rem;
  width: 100%;

  &::placeholder {
    color: $placeholder-color;
  }

  &:not(:focus):hover {
    box-shadow: (
      inset 0 3px 0 0 $grey-150,
      0 2px 6px 0 rgba($grey-900, .1),
      0 1px 2px 0 rgba($grey-900, .15)
    );
  }

  &:focus {
    @extend %base-focus;
  }

  &:disabled,
  &.is-disabled {
    @extend %disabled;

    background-color: $grey-150;
    box-shadow: 0 0 0 1px $grey-350;
    color: $grey-500;
  }

  &:not(:focus).is-success {
    box-shadow: 0 0 0 2px $green;
  }

  &:not(:focus).is-error {
    box-shadow: 0 0 0 2px $red;
  }

  + .hint {
    display: block;
    font-size: $font-size-xs;
    line-height: 1;
    margin-top: $small-spacing;
  }

  &--flat {
    box-shadow: 0 0 0 1px $grey-250
  }

  &--naked {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;

    &:not(:focus):hover {
      box-shadow: none;
    }
  }

  &--short {
    width: auto;
  }

  &--double {
    min-width: 4rem;
    width: 5rem;
  }

  &--double-wide {
    min-width: 4rem;
    width: 7rem;
  }

  &--slim {
    padding: $x-small-spacing $small-spacing;
  }
}

// Input Minimal
//
// Style inputs to be very minimalistic and simple
.input--minimal {
  background-color: rgba(0, 0, 0, 0.1);
  border: 0;
  box-shadow: none;
  line-height: normal;
  margin-bottom: unset;
  min-width: 1rem;
  width: unset;

  &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
    text-align: right;
  }
}

// Inputs with icon.
//
// Styles for form inputs with icons at both sides.
//
// Markup:
// <fieldset class='input--icon{{modifier_class}}'>
//    <svg class='icon--add'</svg>
//    <input type='text' class='input' />
// </fieldset>
//
// :hover - Input hover.
// :focus - Input focus.
// :disabled - Input disabled.
//
// .is-success - Input succes.
// .is-error - Input error.
//
// --right - Input with right icon.
// --block - Input icon that takes al the space.
//
// Styleguide Styleguide.inputs
.input--icon {
  @include position(relative, null null null null);

  display: inline-block;
  width: 100%;

  .icon,
  span {
    color: $placeholder-color;
    z-index: 0;
  }

  .icon {
    polygon,
    path {
      fill: $placeholder-color;
    }
  }

  input {
    z-index: -1;
  }

  + small {
    display: block;
    font-size: $font-size-xs;
    line-height: .8;
  }

  .icon,
  span {
    @include position(absolute, .4rem null null .4rem);
  }

  .input {
    display: inline;
    text-indent: $small-spacing;
    width: 100%;
  }

  &--right {
    .icon {
      @include position(absolute, .4rem null null calc(100% - 24px));
    }

    span {
      @include position(absolute, .4rem null null calc(100% - 1.5rem));
    }

    .input {
      text-indent: 0;
      padding-right: 1.8rem;
    }
  }

  &--block {
    display: block;
    width: 100%;
  }
}

// Input group
//
// Styles for input groups.
//
// Markup:
// <div class='input-group'>
//   <input type='text' class='input{{modifier_class}}' />
// </div>
//
// Styleguide Styleguide.inputs
.input-group {
  margin-bottom: $base-spacing;

  &--minimal {
    display: inline-block;
    margin-bottom: 0;
  }
}

// Checkbox
//
// Styles for checkboxes.
//
// Markup:
// <label class='checkbox'>
//   <input type='checkbox' class='input boolean}' />
//   <span class='checkbox__label'>Label</span>
//   <span class='checkbox__checkmark'></span>
// </div>
//
// Styleguide Styleguide.inputs
.checkbox {
  $size: 20px;

  @include position(relative, null);

  display: block;
  line-height: 1;
  margin-bottom: 0;
  min-height: $size;
  user-select: none;

  &__input {
    @include position(absolute, null);

    cursor: pointer;
    height:  $size;
    opacity: 0;
    width: $size;
    z-index: 1;

    &:checked {
      ~ .checkbox__checkmark {
        background-color: $blue-400;
        box-shadow: (
          inset 0 -3px 0 0 rgba(38, 44, 51, 0.25),
          0 1px 2px 0 rgba(26, 28, 31, 0.1)
        );

        &:after {
          display: block;
        }
      }
    }

    &:disabled {
      ~ .checkbox__checkmark {
        background-color: $grey-150;
        box-shadow: 0 0 0 1px $grey-250;
      }

      &:checked {
        ~ .checkbox__checkmark {
          background-color: $grey-400;
          box-shadow: inset (
            0 -1px 2px 0 rgba(38, 44, 51, 0.25),
            0 1px 2px 0 rgba(38, 44, 51, 0.1)
          );
        }
      }
    }

    &:focus {
      ~ .checkbox__checkmark {
        box-shadow: (
          inset 0 -3px 0 0 $grey-150,
          0 1px 2px 0 rgba(26, 28, 31, 0.1),
          $focus-box-shadow
        );
      }

      &:checked ~ .checkbox__checkmark {
        box-shadow: (
          $focus-box-shadow,
          inset 0 0 0 1px $white,
          inset 0 -3px 0 0 transparentize($base-shadow-color, .85),
          0 1px 2px 0 transparentize($base-shadow-color, .9)
        );
      }
    }
  }

  &__label {
    font-size: $base-font-size;
    line-height: 1.2;
    margin-left: $large-spacing;
    vertical-align: text-bottom;
  }

  &__checkmark {
    @include position(absolute, 0 null null 0);

    background-color: $white;
    border-radius: 4px;
    box-shadow: (
      0 0 0 1px $grey-350,
      inset 0 -3px 0 0 $grey-150,
      0 1px 2px 0 rgba(38, 44, 51, 0.1)
    );
    height: $size;
    width: $size;
    z-index: 0;

    &:after {
      @include position(absolute, 0 null null 8px);

      border-color: $white;
      border-style: solid;
      border-width: 0 2px 2px 0;
      content: '';
      display: none;
      height: 16px;
      transform: rotate(45deg);
      width: 5px;
    }
  }

  &:hover {
    .checkbox__input ~ .checkbox__checkmark {
      box-shadow: (
        0 0 0 1px $grey-350,
        inset 0 3px 0 0 $grey-150,
        0 1px 2px 0 rgba(38, 44, 51, 0.1)
      );
    }

    .checkbox__input:checked ~ .checkbox__checkmark {
      box-shadow: (
        inset 0 -3px 0 0 rgba(38, 44, 51, 0.25),
        0 1px 2px 0 rgba(26, 28, 31, 0.1)
      );
    }
  }
}

// Inline checkbox
//
// Styles for inline checkboxes
//
// Markup:
// <div class='inline-checkbox'>
//   <input type='checkbox' class='input boolean}' />
//   <label for='checkbox'>Label</label>
// </div>
//
// Styleguide Styleguide.inputs
.inline-checkbox {
  vertical-align: middle;

  .hint {
    padding-left: 2.4rem;
  }
}
