// Greys
$grey-100: #f7f8f9;
$grey-150: #f2f3f6;
$grey-200: #dadde3;
$grey-250: #c2c6d0;
$grey-300: #abb0bd;
$grey-350: #959aa8;
$grey-400: #7f8493;
$grey-500: #5c616f;
$grey-600: #4e5360;
$grey-700: #41454f;
$grey-800: #343740;
$grey-900: #1a1c1f;

// Grey colors map
$greys: (
  'grey-100': $grey-100,
  'grey-150': $grey-150,
  'grey-200': $grey-200,
  'grey-250': $grey-250,
  'grey-300': $grey-300,
  'grey-350': $grey-350,
  'grey-400': $grey-400,
  'grey-500': $grey-500,
  'grey-600': $grey-600,
  'grey-700': $grey-700,
  'grey-800': $grey-800,
  'grey-900': $grey-900,
);

// Blues
$blue-100: #c4deee;
$blue-200: #acd0e7;
$blue-300: #89b9dc;
$blue-400: #5fa2ce;
$blue-500: #4585af;
$blue-600: #366e93;
$blue-700: #20567a;
$blue-800: #1f445d;

// Blue colors map
$blues: (
  'blue-100': $blue-100,
  'blue-200': $blue-200,
  'blue-300': $blue-300,
  'blue-400': $blue-400,
  'blue-500': $blue-500,
  'blue-600': $blue-600,
  'blue-700': $blue-700,
  'blue-800': $blue-800,
);

// Pinks
$pink-300: #e280a6;
$pink-400: #df6192;
$pink-500: #b94e77;

// Beige
$beige-100: #faf7ee;
$beige-200: #efe3c6;
$beige-300: #e9d7ae;
$beige-400: #e8cf95;
$beige-500: #c3af83;
$beige-700: #a59471;
$beige-800: #514f4a;

// Beige colors map
$beiges: (
  'beige-100': $beige-100,
  'beige-200': $beige-200,
  'beige-300': $beige-300,
  'beige-400': $beige-400,
  'beige-500': $beige-500,
  'beige-700': $beige-700,
  'beige-800': $beige-800,
);

// Purple
$purple-100: #d2c2df;
$purple-200: #bca4d0;
$purple-300: #a686c0;
$purple-400: #906eaa;
$purple-500: #7a5498;
$purple-600: #643f81;
$purple-700: #52306e;
$purple-800: #45255e;

// Purple colors map
$purples: (
  'purple-100': $purple-100,
  'purple-200': $purple-200,
  'purple-300': $purple-300,
  'purple-400': $purple-400,
  'purple-500': $purple-500,
  'purple-600': $purple-600,
  'purple-700': $purple-700,
  'purple-800': $purple-800,
);

// Red
$red-100: #f8e2de;
$red-200: #e58c7d;
$red-300: #df705d;
$red-400: #cc5a46;
$red-500: #b85544;

// Red colors map
$reds: (
  'red-100': $red-100,
  'red-200': $red-200,
  'red-300': $red-300,
  'red-400': $red-400,
  'red-500': $red-500,
);

// Orange
$orange-100: #ffd27f;
$orange-200: #ffc459;
$orange-300: #ffa500;

// Orange colors map
$oranges: (
  'orange-100': $orange-100,
  'orange-200': $orange-200,
  'orange-300': $orange-300,
);

// Green
$green-100: #bfebcf;
$green-200: #8fdcac;
$green-300: #60ce89;
$green-400: #4bb272;
$green-500: #1b743c;

// Green colors map
$greens: (
  'green-100': $green-100,
  'green-200': $green-200,
  'green-300': $green-300,
  'green-400': $green-400,
  'green-500': $green-500,
);

// Indigo
$indigo-100: #d6ddf1;
$indigo-200: #bac7ec;
$indigo-300: #758fda;
$indigo-400: #5f7cce;
$indigo-500: #4b62a5;

// Indigo colors map
$indigos: (
  'indigo-100': $indigo-100,
  'indigo-200': $indigo-200,
  'indigo-300': $indigo-300,
  'indigo-400': $indigo-400,
  'indigo-500': $indigo-500,
);

// Colors
$beige: $beige-400;
$blue: $blue-400;
$grey: $grey-400;
$indigo: $indigo-400;
$orange: $orange-300;
$pink: $pink-400;
$purple: $purple-400;
$red: $red-400;
$green: $green-400;
$white: #fff;
$black: #000;
$light-blue: #6db2e0;

// Theme colors map
$theme-colors: (
  'grey': $grey,
  'blue': $blue,
  'pink': $pink,
  'beige': $beige,
  'orange': $orange,
  'purple': $purple,
  'red': $red,
  'green': $green,
  'indigo': $indigo,
  'light-blue': $light-blue,
  'white': $white,
  'black': $black
);

// Main colors
$primary: $blue !default;
$secondary: $grey !default;

// Color schemes
$dark: $grey-800;
$light: #f8f9fa;

// Excel colors
$excel-colors: (
  green: #b7d8a8
);

// Pastel colors
$pastel-colors: (
  green: #cbe2c0
);

// Action colors
$action-colors: (
  info: $grey-800,
  success: $green,
  warning: $grey-800,
  danger: $red,
  primary: $primary,
  secondary: $secondary,
  dark: $dark,
  light: $light
);

// Nav colors
$nav-colors: (
  broodmares: $pink-400,
  company: $secondary,
  crops: $light-blue,
  data: $indigo-300,
  matings: $pink-400,
  racehorses: $green,
  sales: #6cd89d,
  stallions: #b07cd9
);

// Text colors
$text-colors: map-merge($action-colors, map-merge($greys, $theme-colors));
