// Button Block
//
// Styles for block buttons.
//
// Markup:
// <a href='#' class='btn-block'>Text</a>
//
// :active - Button hover.
// :hover - Button hover.
// :focus - Button focus.
// :disabled - Button disabled.
//
// Styleguide Styleguide.buttons
.btn--block {
  @extend %focusable;
  @include button(transparent, $base-link-color);
  @include clearfix;

  border-bottom: $base-border;
  display: block;
  height: auto;
  padding: $base-spacing;
  text-align: left;

  &:active {
    box-shadow: none;
    color: $grey-500;
  }

  &:disabled,
  &.is-disabled {
    @extend %disabled;

    color: $grey;
    opacity: 1;
  }

  .icon {
    vertical-align: bottom;

    &:first-of-type {
      margin-right: $small-spacing;
    }

    &:last-of-type {
      float: right;
    }
  }
}
