// Figures
//
// Styles for figures.
//
// Markup:
// <figure></figure>
//
// Styleguide Base.media
figure {
  margin: 0;
  text-align: center;
  width: 100%;

  figcaption {
    color: map-get($text-colors, 'grey');
    line-height: 1.8;
    text-align: left;
  }
}

// Images
//
// Styles for images.
//
// Markup:
// <img src='#'></img>
//
// Styleguide Base.media
img {
  border-radius: $base-border-radius;
  height: auto;
  margin: 0;
}

// Pictures
//
// Styles for pictures.
//
// Markup:
// <picture>
//   <source media="(min-width: 650px)" srcset="#">
//   <source media="(min-width: 650px)" srcset="#">
//   <img src="#" alt="Description">
// </picture>
//
// Styleguide Base.media
picture {
  border-radius: $base-border-radius;
  height: auto;
  margin: 0;
}

// Iframes
//
// Styles for iframes.
//
// Markup:
// <iframe class='iframe' src='#'></iframe>
//
// Styleguide Base.media
iframe {
  border: 0;
  outline: none;
}
