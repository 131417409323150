// Icon mixin
//
// Base icon mixin.
//
// --{icon-name} - Icon name.
//
// Styleguide Base.mixins
@mixin icon($type: base) {
  @if $type == base {
    @each $name, $value in $text-colors {
      &--#{$name} {
        path,
        polygon {
          fill: $value;
        }
      }
    }
  }
}
