// Primary button
//
// Styles for primary buttons.
//
// Markup:
// <a href='#' class='btn--primary {{modifier_class}}'>Primary button</a>
//
// :active - Button hover.
// :hover - Button hover.
// :focus - Button focus.
// :disabled - Button disabled.
// .btn--icon - Button with icon
//
// Styleguide Styleguide.buttons
.btn--primary {
  @extend %primary-shadow;
  @include button($primary, $white);

  &:hover {
    @extend %primary-hover-shadow;
  }

  &:focus {
    @extend %primary-focus-shadow;
  }

  &:active {
    @extend %primary-active-shadow;

    background-color: darken(desaturate(adjust-hue($blue-400, 1), 13.91), 10);
    color: map-get($text-colors, 'white');
  }

  &:disabled,
  &.is-disabled {
    @extend %disabled;

    background-color: $grey-400;
    color: map-get($text-colors, 'light');
    opacity: 1;
  }

  &.btn--icon {
    .icon {
      polygon,
      path {
        fill: $white;
      }
    }
  }
}
