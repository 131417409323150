// Grids
//
// Creates a grid with a defined number of columns
//
// Markup:
// <div class='grid {{modifier_class}}'>
//   <div class='column--{{modifier_class}}'>Column</div>
// </div>
//
// .grid--condensed - Reduces gutter of grid.
//
// --1 - 1 column.
// --2 - 2 columns.
// --3 - 3 columns.
// --4 - 4 columns.
// --5 - 5 columns.
// --6 - 6 columns.
// --7 - 7 columns.
// --8 - 8 columns.
// --9 - 9 columns.
// --10 - 10 columns.
// --11 - 11 columns.
// --12 - 12 columns.
//
// Styleguide Base.helpers
.grid {
  @include grid-container;
  @include grid-collapse;

  .grid__column {
    @for $i from 1 through 12 {
      &--#{$i} {
        @include grid-column($i);

        margin-bottom: $base-spacing;
      }
    }
  }

  &--lg {
    @include grid-container;
    @include grid-collapse;

    .grid__column {
      @for $i from 1 through 12 {
        &--#{$i} {
          @include grid-column($i, $neat-grid-lg);

          padding-right: (6.4rem);
        }
      }
    }
  }

  &--sm {
    @include grid-container;
    @include grid-collapse;

    .grid__column {
      @for $i from 1 through 12 {
        &--#{$i} {
          @include grid-column($i, $neat-grid-sm);
        }
      }
    }
  }
}
