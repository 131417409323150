// Box
//
// Default container box.
//
// Markup:
// <div class='box{{modifier_class}}'>Content</div>
//
// &--white - White box.
//
// Styleguide Styleguide.boxes
.box {
  @include card;
  text-align: left;

  > .heading {
    margin-bottom: $small-spacing;
  }

  > .heading,
  > .heading--group {
    text-indent: $base-spacing;
  }

  &--white {
    background-color: $white;
    border: $base-border;
    border-radius: $base-border-radius;
  }
  
  &--fixed {
    @include position(fixed, 50% null null 50%);

    border-radius: $base-border-radius;
    box-shadow: (
      0 1px 1px 0 rgba($grey-900, .15),
      0 2px 3px 0 rgba($grey-900, .1),
      0 3px 9px 0 rgba($grey-900, .25),
      0 4px 12px 0 rgba($grey-900, .2)
    );
    display: none;
    height: auto;
    min-width: 200px;
    overflow: hidden;
    transform: translate(-50%, -50%);
    width: auto;
    z-index: 1;
  }

  &--floating {
    @include position(absolute, null null null null);

    border-radius: $base-border-radius;
    box-shadow: (
      inset 0 -1px 2px 0 rgba($base-shadow-color, .15),
      0 1px 1px 0 rgba($grey-900, .15),
      0 2px 3px 0 rgba($grey-900, .1),
      0 3px 9px 0 rgba($grey-900, .2)
    );
    display: none;
    height: auto;
    margin-top: $small-spacing / 2;
    min-width: 200px;
    overflow: hidden;
    width: auto;
    z-index: 1;

    &--above {
      @include position(absolute, 0% 50% null null);

      left: auto;
      margin: 0 0 $base-spacing 0;
      top: auto;
    }

    &--below {
      @include position(absolute, 100% 50% null null);

      left: auto;
      margin: $base-spacing 0 0 0;
      top: auto;
    }

    &--left {
      @include position(absolute, 50% 100% null null);

      left: auto;
      margin: 0 $base-spacing 0 0;
      top: auto;
    }

    &--above-align {
      @include position(absolute, 0% null null 0%);

      left: auto;
      margin: 0 0 $base-spacing 0;
      top: auto;
    }

    &--below-align {
      @include position(absolute, 100% null null 0%);

      left: auto;
      margin: $base-spacing 0 0 0;
      top: auto;
    }
  }
}
