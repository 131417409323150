// Button Group
//
// Styles for button groups.
//
// Markup:
// <div class='btn--group'>
//   <a href='' class='btn--primary'>Button 1</a>
//   <a href='' class='btn--primary'>Button 2</a>
// </div>
//
// Styleguide Styleguide.buttons
.btn--group {
  align-items: baseline;
  display: flex;
  justify-content: flex-start;

  .btn {
    margin-right: $small-spacing;

    &:last-child {
      margin-right: 0;
    }
  }

  div {
    display: inline-block;
    margin-right: $small-spacing;
  }
}
