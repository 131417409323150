// Card mixin
//
// Card styles.
//
// .is-collapsed - Collapsed state.
//
// Styleguide Base.mixins
@mixin card($parts: true, $states: true) {
  @extend %focusable;

  background-color: $white;
  border-radius: $base-border-radius;
  box-shadow: 0 0 0 1px $grey-200;
  display: block;
  margin-bottom: 1rem;
  overflow: hidden;
  width: 100%;

  @if ($parts) {
    .card__header {
      @include card-header;
    }

    .card__content {
      @include card-content;
    }

    .card__footer {
      @include card-footer;
    }
  }

  @if ($states) {
    &.is-collapsed {
      .btn--tertiary {
        @include hide-text;
        @include position(relative, null nul null null);

        display: block;
        float: right;
        height: 100%;
        padding: $card-spacing;
        text-align: center;
        width: 30px;

        &::before {
          @include position(absolute, 30% nul null 0);

          border-left: $base-border;
          content: '';
          height: 40%;
        }

        .icon {
          margin-left: -($icon-size / 3);
        }

        .icon polygon,
        .icon path {
          fill: $blue-600;
        }
      }

      .card__header__toggle {
        polygon,
        path {
          fill: $blue-600;
        }
      }

      &:hover {
        overflow: visible;
      }
    }

    .is-empty {
      color: map-get($text-colors, 'grey-500');
      margin: 0;
      padding: $card-spacing;
    }
  }
}
