// Button mixin
//
// Base button mixin.
//
// Styleguide Base.mixins
@mixin button($bg-color, $text-color) {
  appearance: none;
  background-color: $bg-color;
  border-radius: $base-border-radius;
  box-sizing: border-box;
  border: 0;
  display: inline-block;
  color: $text-color;
  font-size: $base-font-size;
  font-weight: 400;
  padding: ($base-spacing / 2) 1.5rem;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  &:disabled,
  &.disabled {
    @extend %disabled;

    opacity: 1;
  }

  &:hover {
    text-decoration: none;
  }
}
