// Hidden elements.
//
// Hides any HTML element
//
// Markup:
// <div class='hidden'>Hidden content</div>
//
// Styleguide Base.helpers
.hidden {
  display: none;
}

// Visible elements.
//
// Makes any HTML element visible
//
// Markup:
// <div class='visible'>Visible content</div>
//
// Styleguide Base.helpers
.visible {
  display: block;
}
