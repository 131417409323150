// Autocomplete
//
// Styles for autcompletes.
//
// Markup:
// <div class='autocomplete'>
//   <div class='autocomplete__input-container'>
//     <svg class='icon--drop-down'></svg>
//     <input type='text' class='autocomplete__input />
//   </div
//  </div>
//   <ul class='autocomplete__options'>
//     <li class='autocomplete__option'>Option</li>
//   </ul>
// </div>
//
// Styleguide Styleguide.front_end
.autocomplete {
  @include options;
  @include position(relative, null null null null);

  &__input-container {
    @include position(relative, null null null null);
  }

  &__input {
    margin: 0;
  }

  &__close {
    @include position(absolute, null $small-spacing .8rem null);

    background: transparent;
    border: 0;
    height: auto;
    margin: 0;
    padding: 0;
    width: auto;
  }

  .input-group {
    margin-bottom: 0;
  }

  .input--minimal {
    &.autocomplete__input {
      min-width: 14rem;
    }
  }
}

