// Text helper
//
// Center text.
//
// Markup:
// <p class='text{{modifier_class}}'>Text</p>
//
// --left - Align text to left.
// --center - Align text to center.
// --right - Align text to right.
// --uppercase - Convert text to uppercase.
// --bold - Bolded text.
// --strike - Strikethrough text.
// --indent - Base text indent.
// --indent--1 - Indent text to 16px.
// --ellipsis - Ellipsed text.
// --primary - Primary text color.
// --secondary - Secondary text color.
// --info - Info text color.
// --success - Success text color.
// --warning - Warning text color.
// --danger - Danger text color.
// --light - Light text color.
// --dark - Dark text color.
// --grey - Grey text color.
// --blue - Blue text color.
// --pink - Pink text color.
// --beige - Beige text color.
// --purple - Purple text color.
// --red - Red text color.
// --green - Green text color.
// --indigo - Indigo text color.
// --light-blue - Light blue text color.
// --white - White text color.
// --black - Black text color.
// --grey-100 - Grey 100 text color.
// --grey-150 - Grey 150 text color.
// --grey-200 - Grey 200 text color.
// --grey-250 - Grey 250 text color.
// --grey-300 - Grey 300 text color.
// --grey-350 - Grey 350 text color.
// --grey-400 - Grey 400 text color.
// --grey-500 - Grey 500 text color.
// --grey-600 - Grey 600 text color.
// --grey-700 - Grey 700 text color.
// --grey-800 - Grey 800 text color.
// --grey-900 - Grey 900 text color.
// --xxl - XXL text size.
// --xl - XL text size.
// --lg - LG text size.
// --md - MD text size.
// --sm - SM text size.
// --xs - XS text size.
// --xxs - XXS text size.
//
// Styleguide Base.helpers
.text {
  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--bold {
    font-weight: bold;
  }

  &--strike {
    text-decoration: line-through;
  }

  &--currency {
    float: right;
    text-align: right;
    text-decoration: inherit;
    width: 100%;

    &::before {
      content: "$";
      float: left;
    }
  }

  &--indent {
    text-indent: $base-spacing;

    @for $i from 0 through 6 {
      &--#{$i} {
        text-indent: $i * 1rem;
      }
    }
  }

  &--wrap {
    white-space: pre-wrap;
  }

  &--ellipsis {
    @include ellipsis;
  }

  @each $name, $value in $text-colors {
    &--#{$name} {
      color: $value;
    }
  }

  @each $name, $value in $text-sizes {
    &--#{$name} {
      font-size: $value;
    }
  }
}
