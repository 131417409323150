// Indicators
//
// Styles for small indicators in elements
//
// Markup:
// <div class='indicator {{modifier_class}}'>
//   { ... }
// </div>
//
// Styleguide Styleguide.indicator
.indicator {
  @include position(relative, 0 null null null);
  height: 100%;
  width: 100%;

  &:after {
    @include position(absolute, 0 0 null null);
    border-color: transparent $grey-300 transparent transparent;
    border-style: solid;
    border-width: 0 6px 6px 0;
    content: '';
    height: 0;
    width: 0;
  }

  &:hover {
    &:after {
      border-color: transparent $blue-100 transparent transparent;
    }
  }

}

.indicator-border {
  @include position(relative, 0 null null null);

  border-color: transparent $grey-300 transparent transparent;
  border-style: solid;
  border-width: 0 3px 0 0;
  height: 100%;
  width: 100%;
}

.indicator-shade {
  @include position(relative, 0 null null null);

  background-color: rgba($grey-300, 0.2);
  border-radius: 2px;
  height: 100%;
  padding: 0 $x-small-spacing;

  &--none {
    background-color: transparent;
  }
}
