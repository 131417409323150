// Icon buttons
//
// Styles for button icons.
//
// Markup:
// <a href='#' class='btn--primary btn--icon{{modifier_class}}'>
//   <svg></svg>
//   <span>Icon text</span>
// </a>
//
// --grey - Icon grey.
// --blue - Icon blue.
// --pink - Icon pink.
// --beige - Icon beige.
// --purple - Icon purple.
// --red - Icon red.
// --green - Icon green.
// --indigo - Icon indigo.
// --light-blue - Icon light blue.
// --white - Icon white.
// --black - Icon black.
// --grey-100 - Icon grey 100.
// --grey-150 - Icon grey 150.
// --grey-200 - Icon grey 200.
// --grey-250 - Icon grey 250.
// --grey-300 - Icon grey 300.
// --grey-350 - Icon grey 350.
// --grey-400 - Icon grey 400.
// --grey-500 - Icon grey 500.
// --grey-600 - Icon grey 600.
// --grey-700 - Icon grey 700.
// --grey-800 - Icon grey 800.
// --grey-900 - Icon grey 900.
//
// Styleguide Styleguide.buttons
.btn--icon {
  padding: $small-spacing .8rem;
  text-align: left;

  > .icon {
    margin-right: ($small-spacing / 2);
  }

  span {
    vertical-align: middle;
  }

  span + .icon {
    margin-right: 0;
  }

  @each $name, $value in $text-colors {
    &--#{$name} {
      color: $value;
      padding-left: 0;

      &:hover {
        color: darken($value, 10%);
      }
    }
  }
}
