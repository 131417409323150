// Card header mixin
//
// Card header styles.
//
// &--with-thumbnail - Card header with a thumbnails.
//
// Styleguide Base.mixins
@mixin card-header($parts: true, $modifiers: true) {
  align-items: center;
  border-bottom: $card-border;
  color: $grey-800;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;

  h1 {
    flex: 1 100%;
    font-size: modular-scale(1, $base-font-size, $minor-third);
    line-height: 1.2;
    margin-bottom: 0;

    a, .title {
      color: $base-text-color;
      display: block;
      padding: 1rem;

      &:hover {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }

      &:focus {
        background-color: $light;
        box-shadow: none;
      }
    }
  }

  &--dark {
    border-bottom: none;

    h1 {
      a, .title {
        color: $white;
        padding: $small-spacing;
      }
    }
  }

  &--display {
    border-bottom: none;

    h1 {
      a, .title {
        padding: $small-spacing;
      }
    }
  }

  @if ($parts) {
    .collapsable__toggle, .dropdown-menu {
      @include position(relative, null 0 null null);

      display: block;
      padding: 1rem 0;

      &::before {
        @include position(absolute, 25% null null 0);

        border-left: $base-border;
        content: '';
        height: 50%;
      }

      .icon {
        margin: 0 $small-spacing;
      }

      .dropdown-menu__item {
        .icon {
          margin-left: 0;
        }
      }

      &:focus::before {
        border-left: 0;
      }
    }

    .btn--tertiary {
      display: block;
      padding: $card-spacing;

      .icon {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        vertical-align: bottom;
      }

      &:focus + div > .collapsable__toggle::before {
        border-left: 0;
      }
    }

    .card__header__desc {
      color: map-get($text-colors, 'grey');
      flex: 0 0 auto;
      margin-right: $base-spacing;
    }
  }

  @if ($modifiers) {
    &--with-thumbnail {
      @include clearfix;

      > a {
        flex: 1;
        padding: .75rem 1rem;

        &:hover {
          text-decoration: none;

          span {
            text-decoration: underline;
          }
        }
      }

      .image {
        border-radius: 1px;
        display: inline-block;
        vertical-align: bottom;
      }

      .no-image + h1,
      .image + h1 {
        display: inline-block;
        float: none;
        margin: 0 0 0 $small-spacing;
        padding: 0;
        width: auto;

        .icon {
          margin-right: ($small-spacing / 2);
        }

        + .icon {
          float: right;
          margin-top: ($small-spacing / 2);
        }
      }
    }
  }
}
