// Form inputs
//
// Styles for form inputs.
//
// Markup:
// <input type='text' class='input' palceholder='Placeholder...' />
//
// :hover - Input hover.
// :focus - Input focus.
// :disabled - Input disabled.
//
// Styleguide Base.inputs
#{$all-text-inputs} {
  appearance: none;
  background-color: $white;
  box-sizing: border-box;
  color: $grey-900;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: 1.8;
}

// Form options
//
// Styles for form options.
//
// Markup:
// <input id='form_field' type='checkbox' class='checkbox' />
// <label for='form_field'>Label</label>
//
// Styleguide Base.inputs
//
// scss-lint:disable QualifyingElement
input[type='checkbox'],
input[type='radio'] {
  display: inline;
  margin-right: $small-spacing / 2;

  + label {
    display: inline;
    margin-left: $small-spacing;
  }
}

// File field
//
// Styles for file fields.
//
// Markup:
// <input type='file' class='input--file' />
//
// Styleguide Base.inputs
//
// scss-lint:disable QualifyingElement
input[type="file"] {
  appearance: none;
  background-color: $white;
  box-sizing: border-box;
  color: $grey-900;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: 1.8;
  margin-bottom: $small-spacing;
  width: 100%;
}
