// Icons
//
// SVG icons
//
// Markup:
// <svg class='icon--{{modifier_class}}'>
//
// --add - Add icon.
// --arrow-back - Arrow back icon.
// --arrow-forward - Arrow forward icon.
// --chevron-down - Chevron down icon.
// --chevron-left - Chevron left icon.
// --chevron-right - Chevron right icon.
// --chevron-up - Chevron up icon.
// --close - Close icon.
// --compare - Compare icon.
// --date - Date icon.
// --download - Download icon.
// --drop-down - Drop down icon.
// --drop-up - Drop up icon.
// --edit - Edit icon.
// --external - xterna, icon.
// --filter - Filter icon.
// --gender-male - Gender male icon.
// --gender-female - Gender female icon.
// --horse - Horse icon.
// --info - Info icon.
// --more - More icon.
// --pedigree - Pedigree icon.
// --picture - Picture icon.
// --picture-no - No picture icon.
// --print - Print icon.
// --rank - Rank icon.
// --search - Search icon.
// --sort - Sort icon.
// --trash - Trash icon.
// --primary - Primary icon color.
// --secondary - Secondary icon color.
// --info - Info icon color.
// --success - Success icon color.
// --warning - Warning icon color.
// --danger - Danger icon color.
// --light - Light icon color.
// --dark - Dark icon color.
// --grey - Grey icon color.
// --blue - Blue icon color.
// --pink - Pink icon color.
// --beige - Beige icon color.
// --purple - Purple icon color.
// --red - Red icon color.
// --green - Green icon color.
// --indigo - Indigo icon color.
// --light-blue - Light blue icon color.
// --white - White icon color.
// --black - Black icon color.
// --grey-100 - Grey 100 icon color.
// --grey-150 - Grey 150 icon color.
// --grey-200 - Grey 200 icon color.
// --grey-250 - Grey 250 icon color.
// --grey-300 - Grey 300 icon color.
// --grey-350 - Grey 350 icon color.
// --grey-400 - Grey 400 icon color.
// --grey-500 - Grey 500 icon color.
// --grey-600 - Grey 600 icon color.
// --grey-700 - Grey 700 icon color.
// --grey-800 - Grey 800 icon color.
// --grey-900 - Grey 900 icon color.
//
// Styleguide Styleguide.icons
.icon {
  @include icon(base);

  vertical-align: middle;

  + span {
    vertical-align: middle
  }

  // Regular icons
  &--add {
    @include icon(base);
  }

  &--arrow-back {
    @include icon(base);
  }

  &--arrow-forward {
    @include icon(base);
  }

  &--bank {
    @include icon(base);
  }

  &--check {
    @include icon(base);
  }

  &--chevron-down {
    @include icon(base);
  }

  &--chevron-left {
    @include icon(base);
  }

  &--chevron-right {
    @include icon(base);
  }

  &--chevron-up {
    @include icon(base);
  }

  &--close {
    @include icon(base);
  }

  &--compare {
    @include icon(base);
  }

  &--date {
    @include icon(base);
  }

  &--download {
    @include icon(base);
  }

  &--drop-down {
    @include icon(base);
  }

  &--drop-up {
    @include icon(base);
  }

  &--edit {
    @include icon(base);
  }

  &--external {
    @include icon(base);
  }

  &--filter {
    @include icon(base);
  }

  &--gender-female {
    @include icon(base);
  }

  &--gender-male {
    @include icon(base);
  }

  &--horse {
    @include icon(base);
  }

  &--info {
    @include icon(base);
  }

  &--matings {
    @include icon(base);
  }

  &--money {
    @include icon(base);
  }

  &--more {
    @include icon(base);
  }

  &--note {
    @include icon(base);
  }

  &--pedigree {
    @include icon(base);
  }

  &--picture {
    @include icon(base);
  }

  &--picture-no {
    @include icon(base);
  }

  &--print {
    @include icon(base);
  }

  &--rank {
    @include icon(base);
  }

  &--ruler {
    @include icon(base);
  }

  &--search {
    @include icon(base);
  }

  &--sort {
    @include icon(base);
  }

  &--trash {
    @include icon(base);
  }
}
