// Dashboard
//
// Styles for dashboard.
//
// Markup:
// <section class='dashboard {{modifier_class}}'>
//   <div class='dashboard__controls'>
//     <div class='dashboard__filter'>
//     </div>
//   </div>
//   <div class='dashboard__report'>
//     // Report goes here
//   </div>
// </section>
//
// Styleguide Styleguide.dashboard
.dashboard {
  display: block;
  width: 100%;

  &__controls {
    @include flex-container;
    background-color: $grey-150;
  }

  &__filter {
    @include flex-item($gutter: $base-spacing, $fixed: true);

    padding: $small-spacing;
  }

  &__action {
    @include flex-item($gutter: $base-spacing, $fixed: true);

    align-self: flex-end;
    padding: $base-spacing;
  }

  &__toolbar {
    @include flex-container($justify: flex-end);
    background-color: $grey-150;

    .dashboard__action {
      @include flex-item($wrappable: false, $gutter: $small-spacing, $fixed: true);

      align-self: flex-end;
      padding: $small-spacing;
    }
  }

  &__report {
    background-color: $white;
    box-sizing: border-box;
    color: $base-text-color;
    padding: $base-spacing $base-spacing $large-spacing $base-spacing;
    width: 100%;

    &--slim {
      padding: 0;
    }
  }
}
