// Progress bars
//
// Styles for progress bar
//
// Markup:
// <div class='progress-bar'>
//   <span class='progress-bar__percentage' style: 'width: X%'></span>
// </div>
//
// Styleguide Styleguide.progress_bars
.progress-bar {
  $base-border-radius: 4px;
  $progress-bar-height: 10px;

  @include position(relative, null null null null);

  background-color: $grey-300;
  border-radius: $base-border-radius;
  height: $progress-bar-height;
  margin-bottom: $small-spacing;
  width: 100%;
  z-index: $base-z-index;

  &__percentage {
    @include position(absolute, 0 null null 0);

    background-color: $blue-400;
    border-radius: $base-border-radius;
    height: $progress-bar-height;
    z-index: 1;
  }
}
