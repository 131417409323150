// Disable focus
//
// Disables default focus.
//
// Styleguide Base.selectors
%disable-focus {
  box-shadow: none;
  outline: none;
}

// Base focus selector
//
// Add disabled properties to element pseudo class.
//
// Styleguide Base.selectors
%base-focus {
  @extend %disable-focus;

  box-shadow: $focus-box-shadow-inset;
}

// Focusable element
//
// Add focus styles to element.
//
// Styleguide Base.selectors
%focusable {
  cursor: pointer;

  &:focus {
    @extend %base-focus;
  }
}

// Non focusable element
//
// Disable focus styles to element.
//
// Styleguide Base.selectors
%non-focusable {
  &:focus {
    @extend %disable-focus;

    box-shadow: none;
  }
}
