// Inline lists
//
// Styles for inline lists distributed in columns.
//
// Markup:
// <div class='horizontal-list{{modifier_class}}'>
//    <dl class='horizontal-list__item'>
//      <dt class='horizontal-list__term'></dt>
//      <dd class='horizontal-list__value'></dd>
//    </dl>
// </div>
//
// --centered - Align values to center.
// --column-1 - List with 1 column.
// --column-2 - List with 2 column.
// --column-3 - List with 3 column.
// --column-4 - List with 4 column.
// --column-5 - List with 5 column.

//
// Styleguide Styleguide.lists
.horizontal-list {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  &--align-start {
    align-items: flex-start;
  }

  &__item {
    flex: 1;

    &--fixed {
      flex: 0 0 auto;
      margin: 0 -.25rem 0 1rem;
    }
  }

  &__term {
    color: map-get($text-colors, 'grey');
    display: inline;
    font-size: $base-font-size;
    margin-right: ($small-spacing / 2);

    &::after {
      content: ':';
    }

    &.is-empty {
      margin-right: 0;

      &::after {
        content: '';
      }
    }
  }

  &__value {
    color: $base-text-color;
    display: inline;
    font-size: $base-font-size;
    margin-right: $base-spacing;
  }

  &--inline {
    display: inline-flex;
  }

  &--centered {
    .horizontal-list__item:first-of-type {
      .horizontal-list__term,
      .horizontal-list__value {
        text-align: left;
      }
    }

    .horizontal-list__item:last-of-type {
      .horizontal-list__term,
      .horizontal-list__value {
        text-align: right;
      }
    }

    .horizontal-list__term,
    .horizontal-list__value {
      display: block;
      margin-right: 0;
      text-align: center;
    }
  }

  @for $i from 0 through 5 {
    &--column-#{$i} {
      @include clearfix;

      .horizontal-list__term {
        font-size: $font-size-sm;
      }

      .horizontal-list__value {
        color: $base-text-color;
        font-size: $base-font-size;
      }

      .horizontal-list__term,
      .horizontal-list__value {
        display: block;
        margin-right: 0;
      }

      .horizontal-list__term::after {
        content: '';
      }
    }
  }
}
