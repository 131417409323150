// Dark button
//
// Styles forghost buttons.
//
// Markup:
// <a href='#' class='btn--dark {{modifier_class}}'>Dark button</a>
//
// :active - Button hover.
// :hover - Button hover.
// :disabled - Button disabled.
// &.btn--icon - Icon button.
// &.btn--upload - Upload button.
//
// Styleguide Styleguide.buttons
.btn--dark {
  $btn-color: #7f8493;

  @include button(transparent, $white);

  background-color: transparentize($grey-900, .65);
  box-shadow: (
    0 1px 2px 0 transparentize($base-shadow-color, .9),
    0 0 0 1px transparentize($grey-300, .65)
  );
  font-size: $font-size-lg;
  padding: .8rem $base-spacing;

  .icon {
    path,
    polygon {
      fill: $white;
    }
  }

  &:hover,
  &:active,
  &.is-active {
    background-color: $btn-color;
    box-shadow: none;
  }

  &:disabled,
  &.is-disabled {
    @extend %disabled;

    opacity: .75;
  }

  &.btn--icon {
    padding-right: $base-spacing + .8;
    text-align: left;
  }
}
