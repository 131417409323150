// Card hover selector
//
// Add styles to hover card list items.
//
// Styleguide Base.selectors
%card-hover {
  &:hover {
    background-color: $card-hover-color;
    text-decoration: none;
  }
}
