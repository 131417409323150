// Alerts
//
// Alert styles for all the different action colors.
//
// Markup:
// <div class='alert--{{modifier_class}}'>Alert text</div>
//
// --primary - Primary alert
// --secondary - Secondary alert
// --info - Info alert
// --success - Success alert
// --warning - Warning alert
// --danger - Danger alert
// --light - Light alert
// --dark - Dark alert
//
// Styleguide Styleguide.alerts
.alert {
  border-radius: .75rem;
  background-color: map-get($action-colors, 'info');
  color: $base-text-color;
  display: inline-block;
  font-size: $base-font-size;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: $large-spacing;
  padding: 1.5rem;
  width: 100%;

  @each $name, $value in $action-colors {
    &--#{$name} {
      background-color: $value;
      color: contrast-switch($value);

      a {
        color: $base-link-color;
        margin-left: $large-spacing;
      }
    }
  }
}
