// Images
//
// Styles for images.
//
// Markup:
// <img src='#' class='image{{modifier_class}}'></img>
//
// --responsive - Responsive image
//
// Styleguide Styleguide.media
.image {
  display: block;

  &:focus {
    box-shadow: none;
  }

  &--responsive {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%;
  }
}

// Images
//
// Styles for images.
//
// Markup:
// <div class='no-image'>
//   <svg class='icon--picture-no'
//   <p>No pictures yet</p>
//   <a href='#' class='btn--secondary'>Add resource</a>
// </div>
//
// &.no-image--thumb - No image thumb size
// &.no-image--sm - No image small size
// &.no-image--xs - No image extra small size
// &.no-image--lg - No image large size
//
// Styleguide Styleguide.media
.no-image {
  background-color: $grey-150;
  border-radius: $base-border-radius;
  color: $base-text-color;
  padding: $base-spacing;
  text-align: center;

  .icon {
    margin-bottom: $small-spacing;
  }

  p {
    color: $grey-400;
    margin-bottom: $base-spacing;
  }

  &--sm {
    border-radius: 1px;
    display: inline-block;
    height: $sm-image-size;
    padding: .4rem;
    width: $sm-image-size;

    .icon {
      margin: ($small-spacing / 2) 0 0;
      vertical-align: middle;
    }
  }

  &--xs {
    border-radius: 1px;
    display: inline-block;
    height: $xs-image-size;
    padding: ($small-spacing / 2);
    width: $xs-image-size;

    .icon {
      vertical-align: top;
    }
  }

  &--lg {
    box-sizing: border-box;
    min-height: 200px;

    .icon {
      margin-top: 30px;
    }
  }
}

// Thumbnail images
//
// Styles for thumnails.
//
// Markup:
// <a href='#' class='thumb'>
//   <img src='#' />
// </a>
//
// Styleguide Styleguide.media
.thumbnail {
  display: block;

  img {
    display: block;
  }
}

// Figure
//
// Styles for figures.
//
// Markup:
// <figure class='figure{{modifier_class}}'>
//  <img src='#' />
//  <figcaption class='thumb'>Text</cfigaption>
// </figure>
//
// &--full-width - Full width figure
// &--with-caption - Figure with caption
// &--with-counter - Figure with counter
//
// Styleguide Styleguide.media
.figure {
  &--full-width {
    img {
      border-radius: 0;
      display: block;
      margin: 0 auto;
    }
  }

  &--with-caption {
    @include position(relative, null null null null);

    .caption {
      @include position(
        absolute,
        ($small-spacing / 2) ($small-spacing / 2) null null
      );

      background-color: transparentize($dark, .1);
      border-radius: 0 $base-border-radius;
      color: map-get($text-colors, 'white');
      font-size: $font-size-sm;
      margin: 0;
      padding: 0 $small-spacing;
      
      &--lg {
        @include position(
          absolute,
          ($small-spacing) ($small-spacing) null null
        );

        font-size: $font-size-lg;
      }
    }
  }

  &--with-counter {
    @include position(relative, null null null null);

    height: $sm-image-size;

    img {
      margin-bottom: 0;
    }

    &__value {
      @include position(absolute, null 0 0 null);

      background-color: transparentize($dark, .1);
      border-bottom-right-radius: $base-border-radius;
      color: map-get($text-colors, 'white');
      display: block;
      font-size: $font-size-sm;
      padding: 0 .4rem;
    }
  }
}

// Image captions
//
// Styles for captions.
//
// Markup:
// <figcaption class='thumb'>Text</cfigaption>
//
// Styleguide Styleguide.media
.caption {
  color: map-get($text-colors, 'white');
  margin: $base-spacing 0;

  &--lg {
    font-size: $font-size-lg;
  }
}

// Thumbnails
//
// Styles for thumbnails.
//
// Markup:
// <div class='thumbnails{{modifier_class}}'>
//  <a href='#' class='thumbnail'><img src='#' /></a>
//  <a href='#' class='thumbnails__more'>+ 1</a>
// </div>
//
// &--viewer - Gallery viewer thumbnails
//
// Styleguide Styleguide.media
.thumbnails {
  @include flex-container($wrap: nowrap, $align: flex-start);

  margin: $small-spacing 0;

  .thumbnail {
    @include flex-item(
      $wrappable: false,
      $gutter: $small-spacing,
      $fixed: true
    );
  }

  &--viewer {
    margin-bottom: $base-spacing;
    text-align: center;

    .thumbnail {
      display: inline-block;
      margin: -3px 0 0 0;

      &.is-active > .image {
        border: 0;
        opacity: .4;
      }

      &:first-child .image {
        border-bottom-left-radius: $base-border-radius;
      }

      &:last-child .image {
        border-bottom-right-radius: $base-border-radius;
      }
    }

    .image {
      border-radius: 0;
      display: inline-block;
    }
  }

  &--bottom {
    margin: $base-spacing 0 0 0;
  }

}

// Iframes
//
// Styles for iframes.
//
// Markup:
// <iframe class='iframe' src='#'></iframe>
//
// Styleguide Styleguide.media
.iframe {
  display: block;
  height: 460px;
  margin: 0 auto;
  width: 380px;
}

// Graphs
//
// Styles for graphs.
//
// Markup:
// <div class='graph'></div>
//
// Styleguide Styleguide.media
.graph {
  border-radius: $base-border-radius;
  box-sizing: border-box;
  padding: $small-spacing $base-spacing;
  margin: 0 $large-spacing;
}
