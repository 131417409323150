// Regular Links
//
// Regular link styles
//
// Markup:
// <a href='#' class='link'>Text</a>
//
// Styleguide Styleguide.links
.link {
  color: $base-link-color;
  text-decoration: none;

  &:focus {
    @extend %disable-focus;

    text-decoration: underline;

    span:not(.badge) {
      text-decoration: underline;
    }
  }

  &--row {
    display: block;
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 0 1rem;

    &:hover {
      text-decoration: none;

      .icon {
        path,
        polygon {
          fill: $light-blue;
        }
      }
    }

    &:focus {
      @extend %base-focus;

      text-decoration: none;
    }
  }

  &--text {
    color: $base-text-color;
    cursor: pointer;
  }

  &--icon {
    cursor: pointer;
    text-align: left;

    > .icon {
      margin-right: ($small-spacing / 2);
    }

    span {
      vertical-align: middle;
    }

    span + .icon {
      margin-right: 0;
    }
  }

  &:disabled,
  &.is-disabled {
    @extend %disabled;

    color: map-get($text-colors, 'light');
    cursor: not-allowed;
    text-decoration: none;
  }
}

// External Links
//
// External link styles
//
// Markup:
// <a href='#' class='link-external'>Text</a>
//
// Styleguide Styleguide.links
.link-external {
  @include position(relative, null null null null);

  color: $base-link-color;
  display: inline-block;
  padding-right: $icon-size;

  &::after {
    @include position(absolute, calc(50% - 12px) 0 null null);

    background-color: $grey-350;
    content: '';
    display: block;
    height: $icon-size;
    mask-image: url('../icons/external.svg');
    transform: scale(.75);
    width: $icon-size;
  }

  &:focus {
    @extend %disable-focus;

    text-decoration: underline;
  }
}
