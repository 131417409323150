// Secondary button
//
// Styles for secondary buttons.
//
// Markup:
// <a href='#' class='btn--secondary {{modifier_class}}'>Secondary button</a>
//
// :active - Button hover.
// :hover - Button hover.
// :focus - Button focus.
// :disabled - Button disabled.
// .btn--icon - Button with icon
//
// Styleguide Styleguide.buttons
.btn--secondary {
  @extend %secondary-shadow;
  @include button($white, $base-link-color);

  &:hover {
    @extend %secondary-hover-shadow;

    border-color: $grey-300;
  }

  &:focus {
    @extend %secondary-focus-shadow;

    border-color: transparent;
  }

  &:active {
    @extend %secondary-active-shadow;

    color: $grey-500;
  }

  &:disabled,
  &.is-disabled {
    @extend %disabled;
    @extend %secondary-disabled-shadow;

    background-color: $grey-150;
    border: 1px solid $grey-250;
    color: map-get($text-colors, 'grey');
    opacity: 1;

    &:focus {
      @extend %secondary-disabled-shadow;
    }

    .icon path,
    .icon polygon {
      fill: $grey;
    }
  }

  &.is-active {
    background-color: $focus-button-background;
    border-color: transparent;
    box-shadow: $focus-box-shadow;
  }

  &.btn--icon {
    path,
    polygon {
      fill: $blue-600;
    }

    &.is-disabled {
      path,
      polygon {
        fill: $grey;
      }
    }
  }
}
