// Selects
//
// Styles for form selects.
//
// Markup:
// <div class='select'>
//   <div class='select__input-container'>
//     <button class='select__toggle'>
//       <svg class='icon--drop-down'></svg>
//     </button>
//     <input type='text' class='select__input />
//   </div
//  </div>
//   <ul class='select__options'>
//     <li class='select__option'>Option</li>
//   </ul>
// </div>
//
// --sm - Smaller size.
//
// Styleguide Styleguide.front_end
.select {
  @include options;
  @include position(relative, null null null null);

  display: inline-block;
  width: auto;

  &__input-container {
    @include position(relative, null null null null);

    margin-bottom: ($small-spacing / 2);
  }

  &__toggle {
    background: transparent;
    border: 0;
    height: auto;
    margin: 0;
    padding: 0;
    width: auto;
  }

  &__input {
    display: inline;
    margin: 0;
    padding-left: 1rem;
  }

  .input-group {
    margin-bottom: 0;
  }

  &--block {
    display: block;
  }

  &--sm {
    .select__input {
      padding: .3rem .8rem;
      width: 8.5rem;
    }

    .select__toggle {
      bottom: .5rem;
      right: .3rem;
    }
  }

  &--minimal {
    .select__input-container {
      margin-bottom: 0;
    }

    .select__input {
      min-width: 10rem;
    }
  }
}

.select__option--checkbox {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  .select__option__checkbox {
    flex: 0 auto;
    padding-right: 35px;
  }

  .select__option__content {
    flex: 1 100%;
  }
}

.select-control {
  @include position(relative, null null null null);

  width: 100%;

  &__container {
    @include position(absolute, null null null null);

    background-color: $white;
    border-radius: $base-border-radius;
    display: block;
    height: 330px;
    overflow: hidden;
    width: 100%;
    z-index: 1;
  }

  &__header {
    @include position(absolute, 0 0 null 0);

    box-shadow: 0 2px 2px 0 rgba($base-shadow-color, .1);
    font-size: $font-size-xs;
    height: 30px;
    z-index: 2;
  }

  &__options {
    @include position(absolute, 30px 0 null 0);

    height: 250px;
    overflow: auto;
  }

  &__footer {
    @include position(absolute, 280px 0 null 0);

    box-shadow: 0 -1px 2px 0 rgba($base-shadow-color, .1);
    height: 50px;
  }
}

// Multi selects
//
// Styles for select multiple.
//
// Markup:
// <div class='multi-select'>
//   <div class='multi-select__input-container'>
//     <button class='select__toggle'>
//       <svg class='icon--drop-down'></svg>
//     </button>
//     <input type='text' class='multi-select__input input--naked' />
//     <div class='multi-select__selection'>
//       <div class='multi-select__selected-item'>
//         Selected item
//         <svg class='icon--close'></svg>
//       </div>
//     </div
//  </div>
//   <ul class='multi-select__options'>
//     <li class='multi-select__option'>Option</li>
//   </ul>
// </div>
//
// Styleguide Styleguide.front_end
.multi-select {
  @include options($options: false, $option: true);

  &__input-container {
    @include position(relative, null null null null);

    align-items: center;
    background-color: $white;
    display: flex;
    height: 40px;
    padding: 0 $small-spacing 0 .4rem;
  }

  &__toggle {
    @include position(absolute, 8px $small-spacing null null);

    background: transparent;
    border: 0;
    height: auto;
    margin: 0;
    padding: 0;
    width: auto;
  }

  &__selection {
    flex: 0 0 auto;
  }

  &__input {
    flex: 1 100%;
    height: 36px;
    margin: 0 0 0 .4rem;
    padding-left: 0;
    width: 25%;

    &:focus {
      @extend %disable-focus;

      box-shadow: none;
    }
  }

  &__selected-item {
    $spacer: .2rem;

    background-color: $focus-button-background;
    border: 1px solid $light-blue;
    border-radius: $base-border-radius;
    cursor: pointer;
    display: inline-block;
    font-size: $font-size-xs;
    line-height: 2;
    margin-right: $small-spacing;
    padding-left: $spacer;

    .icon {
      margin-left: ($spacer / 2);
      vertical-align: middle;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__options {
    border-top: $base-border;
  }
}
