// Disabled selector
//
// Add disabled properties to element pseudo class.
//
// Styleguide Base.selectors
%disabled {
  cursor: not-allowed;
  opacity: .5;
  pointer-events: none;
}

// Disabledable selector
//
// Add disabled properties to element.
//
// Styleguide Base.selectors
%disabledable {
  &:disabled {
    @extend %disabled;
  }
}
