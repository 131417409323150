// Fieldset
//
// Styles for form fieldsets.
//
// Markup:
// <fieldset></fieldset>
//
// Styleguide Base.forms
fieldset {
  background-color: transparent;
  border: 0;
  display: block;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 0;
}

// Legend
//
// Styles for form legends.
//
// Markup:
// <legend>Text</legend>
//
// Styleguide Base.forms
legend {
  margin-bottom: $small-spacing;
  padding: 0;
}

// Label
//
// Styles for form labels.
//
// Markup:
// <label for'#''>Label</label>
//
// Styleguide Base.forms
label {
  color: $grey-500;
  display: block;
  line-height: 1.2;
  margin-bottom: $small-spacing;
}

// Textarea
//
// Styles for form textareas.
//
// Markup:
// <legend>Text</legend>
//
// Styleguide Base.forms
textarea {
  background-color: $white;
  color: $grey-900;
  min-height: 120px;
  min-width: 400px;
  resize: vertical;
}

// Select
//
// Styles for form selects.
//
// Markup:
// <select><option>Option</option></select>
//
// Styleguide Base.forms
select {
  margin-bottom: $small-spacing;
  width: 100%;
}

// Form buttom
//
// Styles for form buttons.
//
// Markup:
// <input type='submit' />
//
// Styleguide Base.forms
#{$all-buttons} {
  @include button($primary, $white);

  cursor: pointer;
  font-family: $base-font-family;
  font-size: $base-font-family;
  line-height: 1.5;
}
