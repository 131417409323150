// Elevation mixin
//
// Elevation mixin styles.
//
// --elevation-1 - Elevation level 1.
// --elevation-2 - Elevation level 2.
// --elevation-3 - Elevation level 3.
// --elevation-4 - Elevation level 4.
//
// Styleguide Base.mixins
@mixin elevation() {
  &--0 {
    box-shadow: 0 0 0 1px $grey-200;
  }

  &--1 {
    box-shadow: (
      0 1px 1px 0 rgba($grey-900, .15),
      0 2px 3px 0 rgba($grey-900, .1)
    );
  }

  &--2 {
    box-shadow: (
      inset 0 -1px 2px 0 rgba($base-shadow-color, .15),
      0 1px 1px 0 rgba($grey-900, .15),
      0 2px 3px 0 rgba($grey-900, .1),
      0 3px 9px 0 rgba($grey-900, .2)
    );
  }

  &--3 {
    box-shadow: (
      0 1px 1px 0 rgba($grey-900, .15),
      0 2px 3px 0 rgba($grey-900, .1),
      0 3px 9px 0 rgba($grey-900, .25),
      0 4px 12px 0 rgba($grey-900, .2)
    );
  }
}
