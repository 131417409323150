// Column lists
//
// Distributes definitions list into X columns.
//
// Markup:
// <div class='column-list--{{modifier_class}}'>
//   <dl>
//     <dt>Term</dt>
//     <dd>Definition</dd>
//   </dl>
// </div>
//
// --1 - 1 column.
// --2 - 2 columns.
// --3 - 3 columns.
// --4 - 4 columns.
//
// Styleguide Base.helpers
@for $i from 1 through 4 {
  .column-list--#{$i} {
    box-sizing: border-box;
    column-count: $i;
    column-gap: $base-spacing;
    padding: 0 $small-spacing 0 0;

    dl {
      margin-bottom: $base-spacing;
    }

    dt {
      margin-bottom: ($small-spacing / 2);
    }

    dd {
      > :not(.badge) {
        @include ellipsis;
      }
    }

    a {
      color: $base-link-color;
    }
  }
}
