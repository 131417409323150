// Tertiary button
//
// Styles for tertiary buttons.
//
// Markup:
// <a href='#' class='btn--tertiary {{modifier_class}}'>Tertiary button</a>
//
// :active - Button hover.
// :hover - Button hover.
// :focus - Button focus.
// :disabled - Button disabled.
// .is-disabled - Button disabled state.
// .btn-condensed - Button without padding.
//
// Styleguide Styleguide.buttons
.btn--tertiary {
  @extend %focusable;
  @include button(transparent, $base-link-color);

  &:hover {
    color: map-get($text-colors, 'blue');
  }

  &:active {
    box-shadow: none;
    color: $grey-500;
  }

  &:disabled,
  &.is-disabled {
    @extend %disabled;

    color: $grey;
    opacity: 1;
  }

  &.is-active {
    background-color: $focus-button-background;
    border-color: transparent;

    path, polygon {
      fill: $blue-600;
    }
  }

  @each $name, $value in $text-colors {
    &--#{$name} {
      color: $value;

      &:hover {
        color: map-get($text-colors, $name);
      }
    }
  }

  &.btn-condensed {
    padding: 0;

    &:focus {
      @extend %disable-focus;

      span {
        text-decoration: underline;
      }
    }
  }
}
