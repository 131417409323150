// Blockquote
//
// Styles for blockquotes.
//
// Markup:
// <blockquote class='blockquote'>
//   <span class='blockquote__badge'></span>
//   <p class='blockquote__quote'>
//     Lorem ipsum ...
//     <span class='blockquote__author'>Author</span>
//   </p>
//   <span class='blockquote__date'></span>
// </blockquote>
//
// Styleguide Styleguide.blockquotes
.blockquote {
  align-items: top;
  display: flex;

  &__author {
    color: $grey-350;
    font-weight: normal;
    margin-left: $small-spacing;

    &::before {
      content: ' – ';
    }
  }

  &__badge {
    flex: 0 0 auto;
    margin-top: .25rem;
    margin-right: $base-spacing;
  }

  &__quote {
    flex: 1 1 100%;
    margin-right: $base-spacing;
  }

  &__date {
    align-self: center;
    color: map-get($text-colors, 'grey');
    flex: 0 0 auto;
  }
}
