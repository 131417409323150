// Lighbox
//
// Styles for modal lighboxes.
//
// Markup:
// <dialog class='lighbox {{modifier_class}}'>
//    <a href='#' class='lightbox__close'>
//      <svg class='icon--close--white'></svg>
//    </a>
// </dialog>
//
// Styleguide Styleguide.cards
.lightbox {
  @include position(absolute, 0 null null 0);

  background-image: (
    radial-gradient(
      circle at top left,
      rgba($black, .9) 100%,
      rgba($grey-900, .9) 0%
    )
  );
  border: 0;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 0 ($large-spacing * 2);
  width: 100%;
  z-index: 1;


  &__close {
    @include position(absolute, 1rem 1rem null null);

    color: $white;
    display: block;

    polygon {
      fill: $white;
    }

    &:hover {
      polygon {
        fill: $grey;
      }
    }
  }

  &__content {
    text-align: center;
    z-index: 1;
  }
}
